<template>
    <div ref="chart_container">

        <div class="d-flex justify-space-between" >
            <div class="subtitle-2 pa-3">Relative Strength</div>
            
            <v-btn-toggle dense borderless class="py-0 mb-2" mandatory color="primary" background-color="neutral"  v-model="chart_type" v-show="selectedTimeframe === 'minute'">
                <v-btn plain class="py-0 my-0" value="standard" @click="setChartType('standard')">Standard</v-btn>
                <v-btn plain class="py-0 my-0" value="cumulative" @click="setChartType('cumulative')">Cumulative</v-btn>
            </v-btn-toggle>
        </div>
        
        <v-divider></v-divider>
        <div v-show="isLoaded" ref="rs_chart" style="height: 180px;" ></div>
        
        <div v-if="!isLoaded" class="pa-10">
          <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
       
    </div>
    
</template>


<script>
    import { EventBus } from '../../modules/EventBus.js';
    import { mapGetters, mapMutations } from 'vuex';
    import { createChart } from 'lightweight-charts';
    import {DateTime} from 'luxon';
        import Utils from '../../modules/Utils';
    export default {
        name: 'RRS',
        data: () => ({
             chart_type: 'cumulative', 
            chart: null,
            stockSeries: null,
            spySeries: null,
            etfSeries: null,
            standardSeries: null,
            last_time: null, 
            ticker: '',
            timeframe: '',
            date: '',

            time_increments: {
                minute: 1000 * 60 * 5,
                hour: 1000 * 60 * 60,
                day: 1000 * 60 * 60 * 24
            }
        }),
        
        mounted(){
            this.timeframe = this.selectedTimeframe;
            this.ticker = this.activeTicker;
            this.date = this.chartDate;
            if(this.rrs){
                this.createChart();
            }


        }, 
        async created(){ 
            EventBus.$on('resize', this.resizeChart)
        },
        async destroyed(){ 
            EventBus.$off('resize', this.resizeChart)
        },
        props:['rrs', 'selectedTimeframe', 'activeTicker', 'isLoaded', 'timeseries', 'details', 'chartDate'  ], 
        computed: {
             ...mapGetters({
                 sector_colors: 'dashboardStore/getSectorColors', 
            }), 
            standard(){
                // if(!this.rrs) return [];
                // let last_time = this.rrs.slice(-1)[0].time; 

                let rs = JSON.parse(JSON.stringify(this.rrs));
                if(!rs.length) return [];
                this.last_time = rs[rs.length - 1].time;
                return rs.map(r => {

                    return {
                        time:  Utils.convertTime(r.time, this.selectedTimeframe), 
                        value: r.relative_strength
                    }
                });
            },
             cumulative(){
                // if(!this.rrs) return [];
                // let last_time = this.rrs.slice(-1)[0].time; 
                let rs = JSON.parse(JSON.stringify(this.rrs));
                if(!rs.length) return [];
                this.last_time = rs[rs.length - 1].time;
                return rs.map(r => {
                    return {
                        time: Utils.convertTime(r.time, this.selectedTimeframe), 
                        value: r.overall_strength
                    }
                });
            },
            stock(){
                
                if(!this.rrs?.relative_strength) return [];
                let stock_data = JSON.parse(JSON.stringify(this.rrs.stock_data));
                if(!stock_data) return [];
                this.last_time = stock_data[stock_data.length - 1].time;
                return stock_data;
            },
            spy(){
                if(!this.stock.length) return []
                return this.cumulative.map(d => {
                    return {
                        value: 0,
                        time: d.time, 
                    }
                });
            },
            etf(){
                return [];
                return this.rrs.etf_data || [];
            }
        },
        methods: {
            resizeChart(){       
                if(this.chart){
                    this.chart.resize(this.$refs.chart_container.offsetWidth, 180);
                }
            }, 
            setChartType(type){
                this.chart_type = type;
                this.createChart();
            },
            resetData(){
                this.stockSeries = null;
                this.spySeries = null;
                this.etfSeries = null;
                this.standardSeries = null;

                try{
                    this.chart.remove();
                } catch(err){
                  //   console.log(err)
                }
            }, 
            whiteSpaceSeries(){
                let data = this.stock;
                if(!data.length) return [];
                
                let vals = [];


                
                for(let i = 0; i < data.length; i++){
                    vals.push({
                        time: Utils.convertTime(data[i].time, this.selectedTimeframe)
                    })
                }

                let starting_time = vals[0].time;
                for(let i = vals.length; i < 78; i++){
                    vals.push({
                        time: starting_time + (i * this.time_increments[this.selectedTimeframe]),
                        value: this.threshold
                    })
                }
                
                const lineSeries = this.chart.addLineSeries({ 
                    color: '#FFFF',
                    priceLineVisible: false,
                    lineWidth: 1,
                    lastValueVisible: false,
                    visible: false
                });
                lineSeries.setData(vals);
            }, 

            createChart(){
                this.resetData();
                
                this.chart = createChart(this.$refs.rs_chart, { 
                    width: this.$refs.chart_container.offsetWidth, 
                    height: 180, 	
                    timeScale: {
                        lockVisibleTimeRangeOnResize: true,
                        borderVisible: false,
                        fixLeftEdge: true,
                        fixRightEdge: true,
                        tickMarkFormatter: (time) => {
                            return '';
                            let datetime = DateTime.fromMillis(time);
                
                            if(this.selectedTimeframe === 'day'){
                                return datetime.toLocaleString();
                            } else {
                                return datetime.toLocaleString(DateTime.TIME_SIMPLE);
                            }
                        } 
                    },
                    priceScale: {
                         borderVisible: false,
                    },	
                    priceLineVisible: false,
                    vertLine: {
                        visible: false,
                        labelVisible: false,
                    },
                    
                    grid: {
                        vertLines: {
                            visible: false, 
                        },
                        horzLines: {
                            color: '#f0f0f0', 
                        }
                    },
                    handleScroll : false,
                    axisPressedMouseMove : false

                });
               
                const chartOptions = { layout: { textColor: 'black', background: { type: 'solid', color: 'white' } } };
                
            //    this.whiteSpaceSeries(); 

                if(this.chart_type == 'standard'){
                    this.makeStandardChart();
                } else if(this.chart_type == 'cumulative'){
                    this.makeCumulativeChart();
                }
                this.chart.timeScale().fitContent();
            },

            makeStandardChart(update){

                if(update){
                    for(let i = 0; i < this.dataponts.length; i++)   {
                        if(this.dataponts[i].time > this.last_time){
                            this.standardSeries.update(this.dataponts[i]);
                        }
                    }


                } else {

                    this.standardSeries = this.chart.addBaselineSeries({ 
                        baseValue: { 
                            type: 'price', 
                            price: 0 
                        }, 
                        priceLineVisible: false,
                        baseLineStyle: 'solid', 
                        baseLineColor: '#323232',
                        topLineColor: 'rgba( 38, 166, 154, 1)', 
                        topFillColor1: 'rgba( 38, 166, 154, .85)', 
                        topFillColor2: 'rgba( 38, 166, 154, .85)', 
                        bottomLineColor: 'rgba( 239, 83, 80, 1)', 
                        bottomFillColor1: 'rgba( 239, 83, 80, 0.85)', 
                        bottomFillColor2: 'rgba( 239, 83, 80, 0.85)'
                    
                    });
                    this.standardSeries.setData(this.standard);
                
                
                }
                this.standardSeries.priceScale().applyOptions({
                    // autoScale: false, // disables auto scaling based on visible content
                    // scaleMargins: {
                        //     top: 0.1,
                    //     bottom: 0.2,
                    // },
                })
            },
            makeCumulativeChart(update){
                
                if(update){
                    for(let i = 0; i < this.stock.length; i++)   {
                        if(this.stock[i].time > this.last_time){
                            this.stockSeries.update(this.stock[i]);
                        }
                    }
                     for(let i = 0; i < this.spy.length; i++)   {
                        if(this.spy[i].time > this.last_time){
                            this.spySeries.update(this.spy[i]);
                        }
                    }
                     for(let i = 0; i < this.etf.length; i++)   {
                        if(this.etf[i].time > this.last_time){
                            this.etfSeries.update(this.etf[i]);
                        }
                    }
                    

                } else {
                    this.stockSeries = this.chart.addLineSeries({ color: '#2962FF', lineWidth: 2, priceLineVisible: false });
                    this.spySeries = this.chart.addLineSeries({ color: '#5d5d5e', lineWidth: 1, priceLineVisible: false });
                //    this.etfSeries = this.chart.addLineSeries({ color: this.sector_colors[this.details.etf], lineWidth: 2, priceLineVisible: false });
                    
                    this.stockSeries.setData(this.cumulative);
                    this.spySeries.setData(this.spy);
                    // this.etfSeries.setData(this.etf);
                }
            },


            setTimeSeries(data){
                this.chart.timeScale().setVisibleRange(data)
            }
        },
        watch: {

            rrs: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    if(!this.isLoaded) return;
                    // if(this.activeTicker == this.ticker && this.timeframe == this.selectedTimeframe && this.date == this.chartDate){
                    //     if(this.chart_type == 'standard'){
                    //         this.makeStandardChart(true);
                    //     } else if(this.chart_type == 'cumulative'){
                    //         this.makeCumulativeChart(true);
                    //     }
                    // } else {
                    //     this.createChart();
                    // }
                    this.createChart();
                }
            }, 
            // isLoaded(){
            //     this.createChart();
            // }, 
            selectedTimeframe(val){
                  if(val === "day"){
                        this.setChartType('standard')
                    }
            },
            timeseries(data){
                this.setTimeSeries(data);
            }
        }
        
    }
 </script> 
 <style>

 </style>
