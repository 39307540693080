<template>

   <div ref="chart_container">

        <v-col class="subtitle-2 pa-3" >{{type | capitalize}}</v-col>
        <v-divider></v-divider>
        <div v-show="isLoaded" :id="`${type}-chart-${activeTicker === 'SPY' ? 'SPY' :  'ticker'}`" style="height: 180px;" ></div>
        <div v-if="!isLoaded" class="pa-10">
          <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
    </div>

</template>

<script>
    import { EventBus } from '../../modules/EventBus.js';

    import { createChart } from 'lightweight-charts';
    import {DateTime} from 'luxon';
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: 'Pressure',
        data: () => ({
            prices:[],
            chart: null,
            longSeries: {},
            shortSeries: {}, 
            zeroSeries: {},
            time_increments: {
                minute: 1000 * 60 * 5,
                hour: 1000 * 60 * 60,
                day: 1000 * 60 * 60 * 24
            }
        
        }),
        async mounted(){
            // EventBus.$on('reload_stock', this.getSpy);

            // this.getSpy();

            if(this.pressure){
                this.createChart();
            }


        }, 
        props: ['pressure', 'timeseries',  'chartDate', 'isLoaded', 'activeTicker', 'selectedTimeframe', 'type', 'active_index', 'intradayChart'], 
        destroyed(){
            // EventBus.$off('reload_stock', this.getSpy);
        }, 
        filters: { 
            capitalize(string){
                return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
            }
        }, 
        async created(){ 
            EventBus.$on('resize', this.resizeChart)
        },
        async destroyed(){ 
            EventBus.$off('resize', this.resizeChart)
        },
        computed:{
            datapoints_up(){
                return this.pressure.map(p => { 
                    return {
                        time: +p.time,
                        value: (p.volume_up + p.volume_down) / 100000,
                        color: '#26A69A'
                    }
                })
            }, 
            datapoints_down(){
                return this.pressure.map(p => { 
                    return {
                        time: +p.time,
                        value: p.volume_down / 100000,
                        color: '#EF5350'
                    }
                })
            }, 
            pressure_long(){     
                return this.pressure.map(p => { 
                    return {
                        time: +p.time,
                        value: p.pressure_long
                    }
                })
                // .filter((p, i) => isNaN(this.active_index) || i <= this.active_index || this.selectedTimeframe === 'day' )
            },
            pressure_short(){
                return this.pressure.map(p => {
                      return {
                        time: +p.time,
                        value: p.pressure_short
                    }
                    
                })
                // .filter((p, i) => isNaN(this.active_index) || i <= this.active_index || this.selectedTimeframe === 'day' )
            },
             zeroline(){
                 
                return this.intradayChart.ticks.map(p => {
                      return {
                        time: +p.time,
                        value: 0
                    }
                    
                })
            },
            power_long(){
                return this.pressure.map(p => {
                    return {
                        time: +p.time,
                        value: p.power_long
                    }
                    
                })
               // .filter((p, i) => isNaN(this.active_index) || i <= this.active_index || this.selectedTimeframe === 'day' )
            },
            power_short(){
                return this.pressure.map(p => {
                      return {
                        time: +p.time,
                        value: p.power_short
                    }
                    
                })
              //   .filter((p, i) => isNaN(this.active_index) || i <= this.active_index || this.selectedTimeframe === 'day' )
            },
            
        },
        methods: {
            resizeChart(){       
                if(this.chart){
                    
                     this.chart.resize(this.$refs.chart_container.offsetWidth, 180);
                }
            }, 
            // addPrice(data){
            //     this.prices.push(data)
            // }, 
            // async getSpy(){
                
            //     let results = await this.api.get(`/tickers/pressure`, {
            //         ticker: this.activeTicker,
            //         date: this.chartDate
            //     });
               
            //      this.prices = results.volume || [];
            //      this.createChart();
            // }, 
            resetData(){
                try{
                    this.chart.remove();
                } catch(err){
                }
            }, 
            updateChart(){
                
                let datapoints_short = this.type === 'pressure' ? this.pressure_short: this.power_short;
                this.shortSeries.update(datapoints_short.slice(-1));

                let datapoints_long = this.type === 'pressure' ? this.pressure_long: this.power_long;
                this.longSeries.update(datapoints_long.slice(-1)); 
            }, 
            createChart(){

                this.resetData();
                let pchart = document.getElementById(`${this.type}-chart-${this.activeTicker === 'SPY' ? 'SPY' :  'ticker'}`)
                

                this.chart = createChart(pchart, { 
                    width: this.$refs.chart_container.offsetWidth,  
                    height: 180,	
                    timeScale: {
                        lockVisibleTimeRangeOnResize: true,
                        // borderVisible: false,
                        fixLeftEdge: true,
                        fixRightEdge: true,
                        tickMarkFormatter: (time) => {
                            
                            let datetime = DateTime.fromMillis(time);
                
                            
                            if(this.selectedTimeframe === 'day'){
                                return datetime.toLocaleString();
                            } else {
                                return datetime.toLocaleString(DateTime.TIME_SIMPLE);
                            }
                        } 
                    },
                    priceScale: {
                         borderVisible: true,
                         
                    },	
                    priceLineVisible: false,
                    vertLine: {
                        visible: false,
                        labelVisible: false,
                    },
                    
                    grid: {
                        vertLines: {
                            visible: false, 
                        },
                        horzLines: {
                            color: '#f0f0f0', 
                        }
                    },
                    //handleScroll : false,
                    axisPressedMouseMove : false

                });
            
                const chartOptions = { layout: { textColor: 'black', background: { type: 'solid', color: 'white' } } };
                this.type = "pressure";
                try{
                    this.shortSeries = this.chart.addLineSeries({ color: '#2962FF', lineWidth: 2, priceLineVisible: false });
                    let datapoints_short = this.type === 'pressure' ? this.pressure_short: this.power_short;
                    this.shortSeries.setData(datapoints_short);
                } catch(err){
                    console.log(err);
                }

                try{
                    this.longSeries = this.chart.addLineSeries({ color: '#5d5d5e', lineWidth: 2, priceLineVisible: false });
                    let datapoints_long = this.type === 'pressure' ? this.pressure_long: this.power_long;
                    this.longSeries.setData(datapoints_long);
                } catch(err){
                    console.log(err);
                }


                // try{
                //     this.shortSeries = this.chart.addHistogramSeries();
                //     console.log("this.datapoints_up", this.datapoints_up)
                //     this.shortSeries.setData(this.datapoints_up);
                //     this.longSeries = this.chart.addHistogramSeries();
                //     this.longSeries.setData(this.datapoints_down);
                // } catch(err){
                //     console.log("err", err);
                   
                // }
                try {
                    this.whiteSpaceSeries()
                } catch(err){
                    console.log(err);
                }

        


            },
            setTimeSeries(data){
                if(!this.chart || !data) return;
                try {
                    this.chart.timeScale().setVisibleRange(data)
                } catch(err){
                    console.log("Err", err)
                }
            },
            whiteSpaceSeries(){
                let data = this.pressure_long;
                
                if(!data.length) return [];
                let vals = [];
                for(let i = 0; i < data.length; i++){
                    vals.push({
                        time: data[i].time
                    })
                }

                let starting_time = data[0].time;
            
                for(let i = 0; i < 79; i++){

                    vals.push({
                        time: starting_time + (i * this.time_increments[this.selectedTimeframe]),
                        value: 0
                    })
                }
                
                const lineSeries = this.chart.addLineSeries({ 
                    color: '#CCCCCC',
                    priceLineVisible: false,
                    lineWidth: 1,
                    lastValueVisible: false,
                    visible: true
                });
                lineSeries.setData(vals);
            }, 
        },
        watch: {
            pressure: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    if(!this.isLoaded) return;
                    // if(this.activeTicker == this.ticker && this.timeframe == this.selectedTimeframe && this.date == this.chartDate){
                    //     if(this.chart_type == 'standard'){
                    //         this.makeStandardChart(true);
                    //     } else if(this.chart_type == 'cumulative'){
                    //         this.makeCumulativeChart(true);
                    //     }
                    // } else {
                    //     this.createChart();
                    // }
                    this.createChart();
                }
            }, 
             timeseries(data){
                this.setTimeSeries(data);
            },
            active_index(){
                this.createChart();
            }
            
        }
    }
</script>