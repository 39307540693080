<template>
    <v-card>
        <v-card-title class="text-subtitle primary" style="color: white;" >Watchlist Item</v-card-title>
         <v-card-text class="py-5">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
            
            <v-text-field
                label="Ticker:"
                v-model="form.ticker"
                disabled
            ></v-text-field>

            <div class="mb-2" >
             <label >Direction:</label><br />
            </div>
            
            <v-btn-toggle v-model="form.direction" required class="mb-5">
                 
                <v-btn text height="40" color="#2ecc71" small value="long">
                    <v-icon color="#2ecc71" class="mr-2">mdi-trending-up</v-icon> Long
                </v-btn>

                <v-btn text height="40" color="#e74c3c" small value="short">
                    <v-icon class="mr-2" color="#e74c3c">mdi-trending-down</v-icon> Short
                </v-btn>
            </v-btn-toggle>
            
            <div class="mb-2" >
             <label >Notes:</label><br />
            </div>
            <v-textarea
                placeholder="Going to the moon..."
                v-model="form.comment"
                outlined
                rows="2"
                
            ></v-textarea>

            </v-form>

         </v-card-text>
        
        <v-divider></v-divider>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="$emit('close')"
            >
                Cancel
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="save"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: "ScanConfig",
    data: () => ({
        form: {},
        valid: false
    }),
    props: ['item'],
    created(){
        this.form = JSON.parse(JSON.stringify(this.item));

    },
    methods:{
        save(){
            this.$store.dispatch('scannerStore/addToWatchlist',  this.form);
            this.$emit('close');
        }
    }
}
</script>