<template>
     <div ref="chart_container">
        <v-col class="subtitle-2 pa-3" >Relative Relative Volume</v-col>
        <v-divider></v-divider>
        <div v-show="isLoaded" ref="rvol_chart" style="height: 180px;"></div>
        <div v-if="!isLoaded" class="pa-10">
          <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
     </div>
</template>


<script>
  import { EventBus } from '../../modules/EventBus.js';
    import { mapGetters, mapMutations } from 'vuex';
    import { createChart } from 'lightweight-charts';
    const { DateTime } = require("luxon");
        import Utils from '../../modules/Utils';
 export default { 
    name: 'RRVol',
    data: () => ({
        rRVol: [], 
        threshold: 1.5,
        loading: false, 
        period: 15, 
        chart: null,
        time_increments: {
            minute: 1000 * 60 * 5,
            hour: 1000 * 60 * 60,
            day: 1000 * 60 * 60 * 24
        },
        series:null,
        last_time: null
    }),

    props:['isLoaded', 'selectedTimeframe', 'RRVol', 'activeTicker', 'timeseries', 'chartDate' ], 
    mounted(){
        this.timeframe = this.selectedTimeframe;
        this.ticker = this.activeTicker;
        this.date = this.chartDate;
        this.threshold = this.selectedTimeframe === 'minute' ? 1.5 : 1;

        if(this.RRVol){
            this.createChart();
        }
    }, 
    async created(){ 
        EventBus.$on('resize', this.resizeChart)
    },
    async destroyed(){ 
        EventBus.$off('resize', this.resizeChart)
    },
    computed:{
        ...mapGetters({
        }), 

        datapoints(){
            
            let source_data = JSON.parse(JSON.stringify(this.RRVol)) ;
            if(!source_data.length) return [];
            
            let bars =  source_data.map(d => {
            
                return {
                    time: Utils.convertTime(d.time, this.selectedTimeframe), 
                    value: d.rrvol,
                    color: d.rrvol >= this.threshold  ? '#2962FF': '#eef2f3',
                }
            });
            this.last_time = bars[bars.length-1].time
            
            return bars;


        },
        threshold_line(){
            let source_data = JSON.parse(JSON.stringify(this.RRVol));
        
            if(!source_data.length) return [];
            
            return [
                { 
                    value: this.threshold, 
                    time:  Utils.convertTime(source_data[0].time , this.selectedTimeframe)  
                }, 
                { 
                    value: this.threshold, 
                    time:  Utils.convertTime(source_data[source_data.length - 1].time , this.selectedTimeframe)
                }   
            ]
        }
    }, 
    methods: {
        resizeChart(){       
            if(this.chart){
                this.chart.resize(this.$refs.chart_container.offsetWidth, 180);
            }
        }, 
        resetData(){
            this.series = null;
            
            try{
                this.chart.remove();
            } catch(err){
                //   console.log(err)
            }
        }, 

        whiteSpaceSeries(){
                
                if(!this.threshold_line.length) return;
                
                const lineSeries = this.chart.addLineSeries({ 
                    color: '#A0A0A0',
                    priceLineVisible: false,
                    lineWidth: 1,
                    lastValueVisible: false,
                    visible: true
                });
                lineSeries.setData(this.threshold_line);
            }, 


        createChart(){
            this.resetData();

            this.chart = createChart(this.$refs.rvol_chart, { 
                    width: this.$refs.chart_container.offsetWidth, 
                    height: 180, 
                    timeScale: {
                        lockVisibleTimeRangeOnResize: true,
                        borderVisible: false,
                        fixLeftEdge: true,
                        fixRightEdge: true,
                        tickMarkFormatter: (time) => {
                            return '';
                            let datetime = DateTime.fromMillis(time);
                
                            if(this.selectedTimeframe === 'day'){
                                return datetime.toLocaleString();
                            } else {
                                return datetime.toLocaleString(DateTime.TIME_SIMPLE);
                            }
                        } 
                    },
                    priceScale: {
                         borderVisible: false,
                    },	
                    priceLineVisible: false,
                    vertLine: {
                        visible: false,
                        labelVisible: false,
                    },
                    
                    grid: {
                        vertLines: {
                            visible: false, 
                        },
                        horzLines: {
                            color: '#f0f0f0', 
                        }
                    },
                    handleScroll : false,
                    axisPressedMouseMove : false

                });
                this.whiteSpaceSeries(); 
                this.series = this.chart.addHistogramSeries({ color: '#26a69a' });
                this.series.setData(this.datapoints);
                this.chart.timeScale().fitContent();
        
        }, 
        updateChart(){
            console.log('updating')
            for(let i = 0; i < this.datapoints.length; i++)   {
                if(this.datapoints[i].time >= this.last_time){
                    this.series.update(this.datapoints[i]);
                }
            }
            

        }, 
        setTimeSeries(data){
                this.chart.timeScale().setVisibleRange(data)
            }
    },
    watch:{
        RRVol: {
            // This will let Vue know to look inside the array
            deep: true,

            // We have to move our method to a handler field
            handler(){
                if(!this.isLoaded) return;
                
                this.createChart();
            }
        }, 
        timeseries(data){
            this.setTimeSeries(data);
        }

    }
 }
</script>
<style>

</style>