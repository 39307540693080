<template>
<v-card elevation="0">
        <v-col class="subtitle-2 pa-3" >Overall Strength</v-col>
        <v-divider></v-divider>
        <div v-show="isLoaded" id="stock-sector-spy-chart" sstyle="height: 180px;"></div>
        <div v-if="!isLoaded" class="pa-10">
          <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
    </v-card>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex';
 const { DateTime } = require("luxon");
    export default {
        name: 'StockSectorSpy',
        data: () => ({
            stock_data: [],
            spy_data: [],
            etf_data: [],
            relative_strength:[],
            loading: false, 
            period: 15, 
            chart: {},
            chart_options: {
                chart: {
                    marginRight: 50,
                    animation: false,
                }, 
                credits: {
                    enabled: false
                },
                 plotOptions: {
                     states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            opacity: 1,
                        }
                    }, 
                    series: {
                        animation: false,
                        enableMouseTracking: false,
                        label: false
                    }, 
                }, 
        
                exporting: {
                    enabled: false
                }, 
                scrollbar: { enabled: false },
                rangeSelector: { enabled: false },
                navigator: {
                    enabled: false
                },
                yAxis: {
                    
                    labels:{
                        align: 'left'
                    }, 
                    plotLines: [],
                },
                xAxis: {
                    plotLines: [],
                    
                    labels: {

                    }
                },
                series: []
            },
            time_increments: {
                minute: 1000 * 60 * 5,
                hour: 1000 * 60 * 60,
                day: 1000 * 60 * 60 * 24
            } 
        }),
        props: ['isLoaded', 'rrs', 'selectedTimeframe', 'activeTicker', 'details' ], 
        mounted(){}, 
        computed: {
             ...mapGetters({
                sector_colors: 'dashboardStore/getSectorColors', 
            }), 
            stock(){
                
                if(!this.rrs?.relative_strength) return [];
                
                let last_time = this.rrs.stock_data.slice(-1)[0].time; 
                let stock_data = this.rrs.stock_data  || [];
            
                return stock_data;

                // return this.rrs.stock_data || [];
            },
            spy(){
                if(!this.stock.length) return []
                let starting_time = this.stock[0].time
                return Array.from({...[],length:78}, (v,i) =>   v ?? { value: null, time:  starting_time += (i * this.time_increments[this.selectedTimeframe]) } ) 
            },
            etf(){
                return this.rrs.etf_data || [];
            }
        },
        methods: {

            createChart(){

                try{
                    this.chart_options.series = [];
                    this.chart.destroy();
                } catch(err){

                }
                
                // this.chart_options.yAxis.max = Math.max(...this.datapoints.map(rrvol => rrvol[1])) * 1.20;
                // this.chart_options.yAxis.max = Math.max(...this.datapoints.map(rrvol => rrvol[1])) * 1.20;
                let _t = this;
                this.chart_options.xAxis.labels.formatter = function () {
                    const dateTimeStr = new Date(_t.spy[this.value].time).toLocaleString()
                    const result = (dateTimeStr.split(", ")[1]).split(':');
                    return result[0] + ":" + result[1];
                };
            
                this.chart_options.series.push({
                    type: 'line',
                    name: 'Stock',
                    data: this.stock.map(d => d.value),
                    color: '#EA4C89',
                })

                this.chart_options.series.push({
                    type: 'line',
                    name: 'SPY',
                    data: this.spy.map(d => 0),
                    color: '#363C4F',
                });

                this.chart_options.series.push({
                    type: 'line',
                    name: 'ETF',
                    data: this.etf.map(d => d.value),
                    color: this.sector_colors[this.details.etf],
                })

        
                this.chart = Highcharts.stockChart('stock-sector-spy-chart', this.chart_options); 
            }
        },
        watch: {
            activeTicker(){  
            
                if(this.isLoaded){
                    this.createChart();
                }
            },
            isLoaded(){
                
                this.createChart();
            }, 
           
            rrs: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                     if(this.isLoaded){
                    this.createChart();
                }
                }
            }, 
    
            selectedTimeframe(){
                if(this.isLoaded){
                    this.createChart();
                }
            }
        }
        
    }
 </script> 