<template>
     <v-card elevation="0">

        <div class="d-flex justify-space-between" >
            <div class="subtitle-2 pa-3">Awesome Force</div>
        
        </div>
        
        <v-divider></v-divider>
        <div v-show="isLoaded" ref="rs_chart" style="height: 180px;" ></div>
        
        <div v-if="!isLoaded" class="pa-10">
          <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
       
    </v-card>
    
</template>


<script>
import { mapGetters, mapMutations } from 'vuex';
import { createChart } from 'lightweight-charts';
  import {DateTime} from 'luxon';
    export default {
        name: 'RRS',
        data: () => ({
            chart_type: 'cumulative', 
            chart: {},
            ticker: '',
            timeframe: '',
            date: '',
            last_time: null, 
            shortSeries: null,
            longSeries: null, 
            time_increments: {
                minute: 1000 * 60 * 5,
                hour: 1000 * 60 * 60,
                day: 1000 * 60 * 60 * 24
            }
        }),
        
   
        mounted(){
            this.timeframe = this.selectedTimeframe;
            this.ticker = this.activeTicker;
            this.date = this.chartDate;
        }, 
        props:['intradayChart', 'selectedTimeframe', 'activeTicker', 'isLoaded', 'timeseries', 'details', 'chartDate' ], 
        computed: {
             ...mapGetters({
                 sector_colors: 'dashboardStore/getSectorColors', 
            }), 
            datapoints_short(){
                if(!this.intradayChart || !this.intradayChart.force_short) return [];

                let force = JSON.parse(JSON.stringify(this.intradayChart.force_short)); 

                // let starting_time = force[0].time;
                
                // let len = force.length;
                // for(let i = len; i < 78; i++){
                //     force.push({
                //         time: starting_time + (i * this.time_increments[this.selectedTimeframe])
                //     })
                // }
                this.last_time = force[force.length - 1].time;
                return force;
            },

            datapoints_long(){
                if(!this.intradayChart || !this.intradayChart.force_long) return [];

                let force = JSON.parse(JSON.stringify(this.intradayChart.force_long)); 

                // let starting_time = force[0].time;
                
                // let len = force.length;
                // for(let i = len; i < 78; i++){
                //     force.push({
                //         time: starting_time + (i * this.time_increments[this.selectedTimeframe])
                //     })
                // }

                return force;
            },
           
        },
        methods: {
            resetData(){
                this.shortSeries = null;
                this.longSeries = null;
                try{
                    this.chart.remove();
                } catch(err){
                  //   console.log(err)
                }
            }, 
            whiteSpaceSeries(){
                let data = this.datapoints_short;
                if(!data.length) return [];
                let starting_time = data[0].time;
                let vals = [];
                for(let i = 0; i < 78; i++){
                    vals.push({
                        time: starting_time + (i * this.time_increments[this.selectedTimeframe])
                    })
                }
                
                const lineSeries = this.chart.addLineSeries({ 
                    color: '#FFFF',
                    priceLineVisible: false,
                    lineWidth: 1,
                    lastValueVisible: false,
                    visible: false
                });
                lineSeries.setData(vals);
            }, 
            setChartType(type){
                this.chart_type = type;
                this.createChart();
            },
            createChart(){

                this.resetData();
                
                this.chart = createChart(this.$refs.rs_chart, { 
                    width: 790, 
                    height: 180,	
                    timeScale: {
                        lockVisibleTimeRangeOnResize: true,
                        borderVisible: false,
                        fixLeftEdge: true,
                        fixRightEdge: true,
                        tickMarkFormatter: (time) => {
                            return '';
                            let datetime = DateTime.fromMillis(time);
                
                            if(this.selectedTimeframe === 'day'){
                                return datetime.toLocaleString();
                            } else {
                                return datetime.toLocaleString(DateTime.TIME_SIMPLE);
                            }
                        } 
                    },
                    priceScale: {
                         borderVisible: false,
                    },	
                    priceLineVisible: false,
                    vertLine: {
                        visible: false,
                        labelVisible: false,
                    },
                    
                    grid: {
                        vertLines: {
                            visible: false, 
                        },
                        horzLines: {
                            color: '#f0f0f0', 
                        }
                    },
                    handleScroll : false,
                    axisPressedMouseMove : false

                });

                
                const chartOptions = { layout: { textColor: 'black', background: { type: 'solid', color: 'white' } } };

                this.whiteSpaceSeries(); 

                this.shortSeries = this.chart.addLineSeries({ 
                    color: '#70a1ff',
                    priceLineVisible: false,
                    lineWidth: 2,
                    lastValueVisible: false,
                });
                
                this.shortSeries.setData(this.datapoints_short);

                this.longSeries = this.chart.addLineSeries({ 
                        color: '#3742fa',
                        priceLineVisible: false,
                        lineWidth: 2,
                        lastValueVisible: false,
                    });
                   

                this.longSeries.setData(this.datapoints_long);


                this.chart.timeScale().fitContent();
            }, 

            updateChart(){
                
                 for(let i = 0; i < this.datapoints_short.length; i++)   {
                    if(this.datapoints_short[i].time >= this.last_time){
                        this.shortSeries.update(this.datapoints_short[i]);
                    }
                 }

                 for(let i = 0; i < this.datapoints_long.length; i++)   {
                     if(this.datapoints_long[i].time >= this.last_time){
                        this.longSeries.update(this.datapoints_long[i]);
                    }
                    
                 }
            }, 

            setTimeSeries(data){
                this.chart.timeScale().setVisibleRange(data)
            }
        },
        watch: {

            intradayChart: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    if(!this.isLoaded) return;
                    if(this.activeTicker == this.ticker && this.timeframe == this.selectedTimeframe && this.date == this.chartDate){
                        this.updateChart();
                    } else {
                        this.createChart();
                    }
                }
            }, 
            isLoaded(){
                this.createChart();
            }, 

            timeseries(data){
                this.setTimeSeries(data);
            }
        }
        
    }
 </script> 
 <style>

 </style>
