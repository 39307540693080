<template>
     <v-card elevation="0">
       <v-card-title>
           <v-row>
               <v-col>Trade Critera</v-col>
               <v-col class="text-right">
                   <v-chip label :color="tradeDirection == 'long' ? '#2ecc71' : '#e74c3c' " class="direction-chip">{{tradeDirection}}</v-chip>
              </v-col>
           </v-row>
       </v-card-title>
       <v-divider></v-divider>
        <v-card-text>

            
             <v-row>
                <v-col cols="4"></v-col>
                <v-col>M5</v-col>
                <v-col>H1</v-col>
                <v-col>D1</v-col>
             </v-row>
             
             <!-- {{trade_criteria}} -->
            <v-container>
                <v-row dense v-for="(item, i) in trade_criteria" :key="i" class="py-0 mb-1">
                    
                    <v-col class="caption" cols="4">{{item.name}}</v-col>
                    <v-col class="mr-1 text-center" :class="item.timeframes['minute'].classname">{{ item.timeframes['minute'].value }} </v-col>
                    <v-col class="mr-1 text-center" :class="item.timeframes['hour'].classname">{{ item.timeframes['hour'].value }} </v-col>
                    <v-col class="text-center" :class="item.timeframes['day'].classname">{{ item.timeframes['day'].value }} </v-col> 
                <!-- <v-col :class="item['1 hour'].classname">{{ item['1 hour'].value }} </v-col>
                    <v-col :class="item['1 day'].classname">{{ item.item['1 day'].value }} </v-col> -->
                </v-row> 
            </v-container>
            <!-- <v-list dense>
                <v-list-item-group color="primary">
                    <v-list-item v-for="(item, i) in scanResultsFiltered" :key="i" >
                        
                    <v-list-item-icon>
                        <v-icon  v-if="!item_status(item)" color="#e74c3c">mdi-close</v-icon>
                        <v-icon  v-if="item_status(item)" color="#2ecc71">mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name" :color="item_status(item) ? 'primary': 'secondary'"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                </v-list> -->
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: 'Sectors',
        data: () => ({
            items: [],
            layout: [
                 {
                    key: 'direction',
                    thresholds: [ 
                        {
                            value: 'short',
                            classname: 'error'
                        },
                        {
                            value: 'long',
                            classname: 'success'
                        },
                    ]
                },
                {
                    key: 'stacked_rs',
                    reverse: false, 
                    thresholds: [ 
                        {
                            value: 0,
                            classname: 'error'
                        },
                        {
                            value: 1,
                            classname: 'success'
                        },
                    ]
                },
                {
                    key: 'above_below_mas',
                    reverse: false, 
                    thresholds: [ 
                        {
                            value: 0,
                            classname: 'error'
                        },
                        {
                            value: 1,
                            classname: 'success'
                        },
                    ]
                },
               
                {
                    key: 'rrvol',
                    reverse: false, 
                    thresholds: [ 
                        {
                            value: 0,
                            classname: 'error'
                        },
                        {
                            value: 1,
                            classname: 'error lighten-4'
                        },
                        {
                            value: 1.5,
                            classname: 'success lighten-4'
                        },
                        {
                            value: null,
                            classname: 'success'
                        }
                    ]
                },
                {
                    key: 'relative_strength',
                    reverse: true, 
                    thresholds: [ 
                        {
                            value: 0,
                            classname: 'error'
                        },
                        {
                            value: 1.5,
                            classname: 'success lighten-2'
                        },
                        {
                            value: null,
                            classname: 'success'
                        }
                    ]
                },
                {
                    key: 'recent_rrvol',
                    reverse: false,
                    thresholds: [ 
                        {
                            value: 0,
                            classname: 'error'
                        },
                        {
                            value: 1,
                            classname: 'error lighten-4'
                        },
                        {
                            value: 1.5,
                            classname: 'success lighten-4'
                        },
                        {
                            value: null,
                            classname: 'success'
                        }
                    ]
                },
                {
                    key: 'gaining_strength',
                    reverse: true,
                    thresholds: [ 
                        {
                            value: 0,
                            classname: 'error'
                        },
                        {
                            value: 1.5,
                            classname: 'success lighten-2'
                        },
                        {
                            value: null,
                            classname: 'success'
                        }
                    ]
                },
                {
                    key: 'gaining_rvol',
                    reverse: true,
                    thresholds: [ 
                        {
                            value: 0,
                            classname: 'error'
                        },
                        {
                            value: 1.5,
                            classname: 'success lighten-2'
                        },
                        {
                            value: null,
                            classname: 'success'
                        }
                    ]
                }
            ]
        }),
        computed: {
            ...mapGetters({

                activeTicker: 'dashboardStore/getActiveTicker',
                // scanResults: 'dashboardStore/getScanResults'
            }), 
            scanResultsFiltered(){
                return this.scanResults && this.scanResults.filter(i => i.datatype)
            },
            tradeDirection(){
                let direction =  this.scanResults && this.scanResults.find(i => i.name.toLowerCase() === "direction" && i.timeframe === "minute" );
                return direction ? direction.value : null
            },
            trade_criteria(){
                let obj = {};
                
                if(!this.scanResults || !this.scanResults.length) return;
                for(let i = 0; i < this.scanResults.length; i++){
                    
                    let scan = this.scanResults[i];
                    let layout_object = this.layout.find(l => l.key === scan.key);
                    if(!layout_object) continue;
                    obj[scan.key] = obj[scan.key] || {
                        name: scan.name,
                        timeframes: {
                            "1 day" : {
                                value: 0,
                                classname: 'subdued'
                            },
                            "1 hour" : {
                                value: 0,
                                classname: 'subdued'
                                },
                            "5 minute" : {
                                value: 0,
                                classname: 'subdued'
                            } 
                        }
                    } 
                    
                    let value = '';
                    let classname = '';
                    let layout_entry = null;
                    
                        
                    switch(scan.datatype){
                        case 'Boolean':
                            value =  +scan.value ? 'Y': 'N';       
                        
                            
                            layout_entry =  layout_object.thresholds.find(t => t.value == scan.value);
                            classname = layout_entry ? layout_entry.classname : '';
                            break;

                        case 'String':
                            value = scan.value
                            layout_entry = layout_object.thresholds.find(t => t.value == scan.value);
                            classname = layout_entry ? layout_entry.classname : '';
                            break;
                        case 'Number': 
                            value = Math.round(+scan.value * 1e2 ) /1e2;
                            layout_entry =  layout_object.thresholds.find(t => +t.value >= scan.value);
                            if(!layout_entry) layout_entry = layout_object.thresholds[layout_object.thresholds.length - 1]
                            classname = layout_entry ? layout_entry.classname : '';
                            break;
                        default: 
                            value = scan.value;
                            classname = '';
                    }

                    obj[scan.key].timeframes[scan.timeframe] = {
                        value: value,
                        classname: classname,
                    }

                }

                return obj

            }   


        },

        filters:{
            
        }, 
        methods:{
            getParameterValue(timeframe, parameter, element){
                if(!this.scanResults || !this.scanResults.length) return;
                let param = this.scanResults.find(r => r.timeframe === timeframe && r.key === parameter);
                
                if(!param) return {
                    class: "",
                    value: '-'
                };
                switch(param.datatype){
                    case 'Boolean':

                        return !!param.value ? 'Yes': 'No'
                        break;
                    case 'Number': 
                        return Math.round(+param.value * 1e2 ) /1e2
                }

    
            }, 
            getParameterName(key){
                let param = this.scanResults.find(r => r.key === key);
                  if(!param) return null;
                return param ? param[element]: '-';

            }, 
          
            item_status(item){
                if(item.datatype == 'Boolean') return +item.value > 0;
                let config = this.scanConfigurations[0].settings[item.key];
                return +item.value >= +config.value;
            },
            

        },
        watch: {
            scanConfigurations(){ 
            },
        }
    }
 </script> 

 <style scoped>
    .direction-chip{
        color: white;
    }
 </style>