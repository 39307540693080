<template>
  <!-- <v-btn @click="reload" depressed color="neutral" class="ml-3" small><v-icon small>mdi-reload</v-icon></v-btn>
<v-btn color="error" small outlined class="ma-1" @click="clearAllTabs"><v-icon small>mdi-close</v-icon></v-btn> -->
    <div>
        <div v-if="activeTicker" class="chart-container">
            <div class="d-flex ticker-info justify-space-between py-2" style="flex:1">
                <!-- Ticker Details -->
                
                <div class="ticker py-0 px-4 flex-column">
                    <div class="d-flex ">
                        <div class="py-0 text-h5 mr-2"><strong>{{ticker_details.symbol}} </strong></div>
                        
                        <template v-if="game_mode && isTicker">
                            <v-chip class="ml-2 px-4"  :color="sectorColors[ticker_details.etf]" label><strong>{{ticker_details.etf}}</strong></v-chip> 
                        </template>
                        <template v-else-if="isTicker" >
                            <v-chip v-if="!ticker_details.is_etf" class="ml-2 px-4"  :color="sectorColors[ticker_details.etf]" label><strong>{{ticker_details.etf}}</strong></v-chip> 
                            <v-btn v-if="!tickerInWatchList(ticker_details.symbol) && !ticker_details.is_etf" @click="addToWatchlist(ticker_details.symbol)" depressed color="neutral" class="ml-3 unset-case text--darken-3 neutral--text" ><v-icon  class="mr-2">mdi-star-outline</v-icon>Watchlist</v-btn>    
                            <v-btn dark v-else-if="!ticker_details.is_etf" @click="removeFromWatchlist" depressed color="primary" class="ml-3 unset-case"><v-icon class="mr-2">mdi-star</v-icon>Watchlist</v-btn>    
                            <v-badge v-if="!ticker_details.is_etf" color="primary" overlap :content="alerts.length" :value="alerts.length">
                                <v-btn @click="configureAlerts"  color="neutral" class="ml-3 text--darken-3 neutral--text" depressed ><v-icon >mdi-bell-outline</v-icon></v-btn>
                            </v-badge> 
                        </template>
                    </div>
                    
                    <div class="ticker-name">{{ticker_details.name}} </div>

                </div>

                <div class="d-flex"  v-if="isTicker">
        
                    <div class="chart-kpi mr-2 flex-grow" v-if="RRS && RRS.length">
                        <div class="chart-kpi-metric" v-if="RRS">{{RRS.slice(-1)[0].overall_strength | round }}</div>
                        <div class="chart-kpi-label text--darken-3">RS/RW</div>
                    </div>

                    <div class="chart-kpi mr-2 flex-grow" v-if="RRVol.length">
                        
                        <div class="chart-kpi-metric" >{{ RRVol.slice(-1)[0].rrvol | round}}</div>
                        <div class="chart-kpi-label">RVOL</div>
                    </div>
                    
                    <div class="chart-kpi mr-2 flex-grow" v-if="dailyChart && dailyChart.prices && dailyChart.prices.length">
                        <div  class="chart-kpi-metric">{{ dailyChart.prices.slice(-1)[0].atr.toFixed(2) }}</div>
                        <div class="chart-kpi-label">ATR</div>
                    </div>
                    
                    <div class="chart-kpi mr-2 flex-grow" @click="viewScanMetrics">
                        <div class="chart-kpi-metric"><v-icon>mdi-gauge</v-icon></div>
                        <div class="chart-kpi-label">VIEW</div>
                    </div>

                </div>
             
            </div>


            <!-- Scanner Note -->
            <div v-if="!game_mode && tickerInWatchList(ticker_details.symbol)" class="py-2 px-4 my-1 mr-2 scan-note">
                <div class="text-caption font-weight-light">Note:</div>
                <div class="text-body-2">{{ tickerInWatchList(ticker_details.symbol).comment }}</div>
            </div>

            <!-- <div style="flex: 1; width: 100%"> -->
                 <!-- <div class="chart-kpi mr-2 flex-grow">
                    <div>
                        <v-slider
                            :color="volume_diff > 0 ? '#2ecc71': '#e74c3c' "
                            :track-color="volume_diff > 0 ? '#2ecc71': '#e74c3c' "
                            dense 
                            readonly
                            :value="volume_diff"
                            min="-100"
                            max="100"
                    
                        ></v-slider>
                    </div>
                    <div class="chart-kpi-label text--darken-3">Pressure</div>
                </div> -->

                    <!-- <div class="chart-kpi mr-2 flex-grow" >
                        <div>
                            <v-slider
                                dense 
                                readonly
                                :color="distance_diff > 0 ? '#2ecc71': '#e74c3c' "
                                :track-color="distance_diff > 0 ? '#2ecc71': '#e74c3c' "
                                :value="distance_diff"
                                min="-100"
                                max="100"
                            ></v-slider>
                        </div>
                        <div class="chart-kpi-label text--darken-3">Resistance</div>
                    </div> -->
            <!-- </div> -->

            <div class="d-flex pa-4 text-subtitle-1 justify-space-between">
                <div>
                    <strong>{{selectedTimeframe | capitalize}} Chart  
                        <!-- {{ consolidation}} -->
                        </strong>                
                </div>
                
                <v-spacer></v-spacer>

                     <v-btn-toggle dense borderless class="py-0" mandatory color="primary" background-color="neutral"  v-model="selectedTimeframe">
                        <v-btn  plain class="py-0" value="minute">M5</v-btn>
                        <v-btn  plain class="py-0" value="day">D1</v-btn>
                     </v-btn-toggle>
                    <div style="width: 1px; margin: 3px 10px; border-right: 1px solid #e2e2e2; flex 0 1; " ></div>&nbsp;<div>
                      <Lines v-model="lines" />



                </div>
            </div> 


            <!-- Ticker Chart -->
        
            <div class="chart-holder"> 
                
                <TVChart 
                    :isLoaded="isLoaded(activeTicker)"
                    :lines="lines" 
                    :selectedTimeframe="selectedTimeframe"
                    :activeTicker="activeTicker"
                    :chartDate="chartDate"
                    @timechange="updateTimeSeries"
                    @clicked="chartClick"
                    :dailyChart="dailyChart"
                    :chartData="chart"
                />
            </div>

          
            <!--
            <div class="chart-holder" > 
                <Pressure 
                    type="pressure"
                    :chartDate="chartDate"
                    :isLoaded="isLoaded(activeTicker)"
                    :active_index="active_index"
                    :selectedTimeframe="selectedTimeframe"
                    :activeTicker="isTicker ? activeTicker: 'SPY'"
                    :timeseries="activeTimeSeries"
                    :details="ticker_details"
                    :pressure="pressure"
                    :intradayChart="intradayChart"
                />
            </div> -->
            
            <div class="chart-holder" v-if="isTicker"> 
                <RRS 
                    :chartDate="chartDate"
                    :isLoaded="isLoaded(activeTicker)"
                    :selectedTimeframe="selectedTimeframe"
                    :activeTicker="activeTicker"
                    :timeseries="activeTimeSeries"
                    :details="ticker_details"
                    :rrs="RRS"
                />
            </div> 
     
            <div class="chart-holder" v-if="isTicker"> 
                <RVol 
                    :chartDate="chartDate"
                    :isLoaded="isLoaded(activeTicker)"
                    :selectedTimeframe="selectedTimeframe"
                    :activeTicker="activeTicker" 
                    :timeseries="activeTimeSeries"       
                    :RRVol="RRVol"
                />
            </div>            
        </div>
        <div v-else-if="!activeTicker">
        <v-container >
             <v-row >
                <v-col class="text-center panel-loading"> 
                    
                    <v-icon large class="mb-2" color="#e67e22">mdi-alert-octagram</v-icon>
                    <div class="overline" style="color: #323232">Please Select a ticker</div>
                    <div class="body-2" style="color: #a0a0a0">Use the search bar or click a result from the scanner</div>
                
                 </v-col>
             </v-row>
        </v-container>
        </div>
           
         <v-dialog persistent v-if="showEditWatchlist" v-model="showEditWatchlist" max-width="600">
             <configure-watchlist-item :item="watchlistItem" @close="closeModal"></configure-watchlist-item>   
        </v-dialog>


        <v-dialog persistent v-if="showAlertConfig" v-model="showAlertConfig" max-width="600">
             <configure-alerts :alerts="alerts" @close="closeModal" @save="saveAlerts"></configure-alerts>   
        </v-dialog>
    
         <v-dialog v-if="showScanMetrics" v-model="showScanMetrics" max-width="600" >
             <scan-metrics :metrics="metrics" @close="showScanMetrics = false"></scan-metrics>   
        </v-dialog>


    </div>
    
</template>

<script>


    import { createChart } from 'lightweight-charts';
    import TVChart from "./TVChart"

    import { DateTime } from "luxon";
    import TrendChart from "./TrendChart"
    import ScanMetrics from "./ScanMetrics"
    import ConfigureAlerts from "./ConfigureAlerts"
    import RVol from "./RVol"
    import RRS from "./RRS"
    import Pressure from "./Pressure"
    import Power from "../spy/Power"
    
    import StockSectorSpy from "./StockSectorSpy" 
    // import Watchlist from "./Watchlist"
    import Lines from "./Lines"
    import Chart from "./Chart"
    import TradeChecklist from "./TradeChecklist"
    import ConfigureWatchlistItem from "./ConfigureWatchlistItem"
    import Force from "./Force"
    import { mapGetters, mapMutations } from 'vuex';
 
    
    export default {
        name: 'Dashboard',
         data: () => ({
            watchlistItem: {}, 
            showEditWatchlist: false,
            lines: ['smas', 'emas'],
            display: 'intraday',
            showAlertConfig: false,
            activeTimeSeries: {},
            showScanMetrics: false,
            
         }),
         created(){
             
             if(this.$route.query.lines){
                 this.lines = this.$route.query.lines

             }


         },
          computed: {
            ...mapGetters({

                isLoaded: 'dashboardStore/isLoaded',
                getActiveTicker: 'dashboardStore/getActiveTicker',
                getDailyChart: 'dashboardStore/getDailyChart',
               //  tickerList: 'dashboardStore/getTickerList',
                details: 'dashboardStore/getDetails',
                getIntradayChart: 'dashboardStore/getIntradayChart',
                getIntradayTrendsChart: 'dashboardStore/getIntradayTrendsChart',
                getRRS: 'dashboardStore/getRRS',
                getRRVol: 'dashboardStore/getRRVol',
                getPressure: 'dashboardStore/getPressure',
                getChart: 'dashboardStore/getChart',
                sectorColors: 'dashboardStore/getSectorColors',
                selectedTimeframe: 'dashboardStore/getSelectedTimeframe',
                watchlist: 'scannerStore/getWatchlist',
                activeAlerts: 'notificationsStore/getActiveAlerts', 
                chartDate: 'dashboardStore/getChartDate',
                current_scan: 'scannerStore/getCurrentScan',
                stockMetrics: 'dashboardStore/getMetrics',
                
            }), 
            metrics(){ 


                if(!this.game_mode){
                    return this.stockMetrics(this.activeTicker, this.selectedTimeframe, this.active_index);
                    // return this.current_scan(this.getActiveTicker);
                } else {
                    return this.scan_data && this.scan_data.find(s => s.symbol === this.getActiveTicker); 
                }
            }, 
            isTicker(){
                return this.activeTicker !== 'SPY';
            }, 
            // intradayChart(){
            //     return this.getIntradayChart(this.activeTicker, this.selectedTimeframe, this.active_index);
            // }, 
            RRVol(){
                
                return this.getRRVol(this.activeTicker, this.selectedTimeframe, this.current_time?.ts);
            },

            RRS(){
                return this.getRRS(this.activeTicker, this.selectedTimeframe, this.current_time?.ts);
            },


            pressure(){
                return this.getPressure(this.activeTicker, this.selectedTimeframe, this.active_index);
            },

            chart(){
                return this.getChart(this.activeTicker, this.selectedTimeframe, this.current_time?.ts);            
            },

            volume_diff(){
                return this.minuteChart.volume_diff;
            }, 
            distance_diff(){
                return this.minuteChart.distance_diff;
            }, 

            minuteChart(){
                
                return this.getChart(this.activeTicker, 'minute', this.current_time?.ts);
                              
                // return vals || {};
            },

            dailyChart(){
                
                return this.getChart(this.activeTicker, 'day', this.current_time?.ts);         
            
                              
                // return vals || {};
            },

            // intradayTrendsChart(){
            //     return  this.getIntradayTrendsChart(this.activeTicker, this.selectedTimeframe, this.active_index);
            // },

            activeTicker(){
                return this.ticker || this.getActiveTicker;
            }, 
            
            ...mapMutations({
                setActiveTicker: 'dashboardStore/setActiveTicker'
            }), 
 
            selectedTimeframe: {
                get () {
                    return this.$store.getters['dashboardStore/getSelectedTimeframe']
                },
                set (timeframe) {
                    return this.$store.dispatch('dashboardStore/setSelectedTimeframe', timeframe)
                }
            },
            
            alerts(){
                return this.activeAlerts.filter(a => a.Ticker.symbol === this.activeTicker);
            },

            ticker_details() {
                return this.details(this.activeTicker)
            }, 

            // consolidation(){
            //     let intradayTrends = this.intradayTrendsChart;
            //     if(intradayTrends?.consolidation){
            //         return ' | ' + intradayTrends.consolidation.formation + ' ' + intradayTrends.consolidation.consolidationPattern ;
            //     }
            //     return '';
            // }
    
        },
        props:['game_mode', 'ticker', 'active_index', 'scan_data', 'current_time'], 
        components:{
            TrendChart,
            TradeChecklist, 
            Chart,
            TVChart,
            RVol,
            RRS,
            StockSectorSpy,
            ConfigureAlerts,
            Lines,
            ConfigureWatchlistItem,
            ScanMetrics,
            Force,
            Pressure,
            Power
        },     
        filters:{
            capitalize(string){
                return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
            },
            round(num){
                return Math.round(num * 1e2) / 1e2;
            },
        },
        methods:{
            chartClick(time){
                
                if(this.selectedTimeframe == 'minute') return;
                console.log("time", time)
                let date = new DateTime.fromObject(time).setZone('America/New_York');
                
                this.$store.dispatch('dashboardStore/setDate', {
                    date: date.toISODate()
                });
                this.selectedTimeframe = 'minute'

            },
            viewScanMetrics(){
                this.showScanMetrics = true;
            }, 
            updateTimeSeries(data){
                this.activeTimeSeries = data;
            }, 
            configureAlerts(){
                this.showAlertConfig = true;
            }, 
            saveAlerts(){

            }, 
            clearAllTabs(){
                this.$store.commit('dashboardStore/clearAllTabs'); 
            }, 
            reload(){
                this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: this.activeTicker,
                    set_active: true
                });
            },
            close(){
                this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: null,
                    set_active: true
                });
            }, 
            addToWatchlist(ticker){
                
                let t = ticker || this.activeTicker;
                
                let item = this.watchlist.Tickers && this.watchlist.Tickers.find(w => w.symbol === t);
                

                this.watchlistItem = {
                    ticker:  item && item.symbol || t,
                    direction: item && item.direction || '',
                    comment: item && item.comment || null,
                    tag: item && item.tag || null
                };
                this.showEditWatchlist = true;
            }, 
            closeModal(){
                this.showEditWatchlist = false;
                this.showAlertConfig = false;
            }, 
            // saveToWatchlist(){

            //     // this.$store.dispatch('scannerStore/addToWatchlist',  {
            //     //     ticker: this.activeTicker,
            //     //     direction: null,
            //     //     comment: '',
            //     //     tag: ''      
            //     // });

            //     this.$store.dispatch('scannerStore/addToWatchlist',  this.watchlistItem);
            //     this.closeModal();
            //     EventBus.$emit('updateWatchlist');
            // },
            tickerInWatchList(ticker){
                return this.watchlist.Tickers &&  this.watchlist.Tickers.find(w => w.symbol === ticker)
            },
            removeFromWatchlist(){
                this.$store.dispatch('scannerStore/removeFromWatchlist',  {
                    ticker: this.activeTicker
                });
            }, 

        }
    }
</script>

<style>
.panel-loading{
    min-height: 500px;
    padding: 15% 25% 0 ;
}




    .chart-kpi{
        background-color: #eef2f3;
        border-radius: 4px;
        padding: 4px 15px;
        text-align: center;
        
    }

    .chart-kpi-label{
        color: #b6bdc3;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        
    }

    .chart-kpi-metric{
        font-weight: 800;
        margin-bottom: 0;
        line-height: 28px;
        font-size: 18px;
        color: #5d5d5e;

    }
    .ticker-info{
        border-bottom: 1px solid #e2e2e2;
    }

    .scan-note{
        background-color: #EEF2F3;
        border-radius: 4px;
    }

    .flex-break {
        flex-basis: 100%;
        height: 0;
    }
    .ticker-name{
        color: #b6bdc3;
        font-weight: bold;
        font-size: 14px;
    }

    .chart-holder{
        border-top: 5px solid #eef2f3;
        border-left: 5px solid #eef2f3;
        border-right: 5px solid #eef2f3;
        padding: 5px;
    }
    .chart-container{
        border-bottom: 5px solid #eef2f3;

    }
</style>
