<template>
    <v-card elevation="0" class="mt-1">

        <div v-show="sLoaded" id="trend-chart" style="height: 360px;"></div>        
        <div v-if="!isLoaded" class="pa-10">
          <p class="text-body-2 text-center">Retreiving Data</p>
            <v-progress-linear
                color="accent"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
       
    </v-card>
</template>


<script>
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: 'TrendChart',
        data: () => ({

            loading: false,

            timeframe: 'day',
            multiplier: "1",
            trend_touches: 2,
            trend_fuzziness: 14,
            start_date: '2022-02-05',
            end_date: '2022-08-05',
            period_length: 3,
            min_trend_length: 10, 
       
       
            ticks: [],
            maximas: [],
            minimas: [],
            trends: [],
            trend: null,
            active:{
                maximas: [], 
                minimas: []
            },


            tick_data: [],
            trade_data: {},
            time_scale: "1 Min", 
            time_scales: ["1 Min", "15 Min"],
            chart: {},
            chart_options: {
                chart: {
                    marginRight: 50,
                    animation: false,
                    
                }, 
                credits: {
                    enabled: false
                },
        
                exporting: {
                    enabled: false
                }, 
                scrollbar: { enabled: false },
                
                plotOptions: {
        
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            opacity: 1,
                        }
                    }, 
                    series: {
                        animation: false,
                        enableMouseTracking: false,
                        label: false
                    }, 
                    candlestick: {
                        color: '#e74c3c', 
                        upColor: '#2ecc71',
                        pointPadding: 0,
                        groupPadding: .15
                    },
                }, 
                rangeSelector: {
                enabled: false
                },
                navigator: {
                    enabled: false
                },
              
                yAxis: {
                    tickSize: 100,
                    endOnTick: false,
                    startOnTick: false,
                    labels:{
                            align: 'left'
                        },
                    plotLines: [],
                },
                xAxis: {
                    plotLines: [],
                    labels: {
                    }
                },
                series: []
            }
            
        }),

        components:{},
        props: ['ticker', 'lines'], 
        async mounted(){ 
           
        },
        computed: {
             ...mapGetters({
                isLoaded: 'dashboardStore/isLoaded',
                selectedTimeframe: 'dashboardStore/getSelectedTimeframe',
                dailyChart: 'dashboardStore/getDailyChart',
                activeTicker: 'dashboardStore/getActiveTicker'
            }), 
            filtered_ticks(){
                return this.tick_data.slice(this.offset, this.ticks_to_show)    
            },
            chartDates(){
                let start = new Date(this.tick_data[0].time);
                let end = new Date(this.tick_data[this.tick_data.length - 1].time);
                return start.toLocaleDateString() + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString() + " " + end.toLocaleTimeString();
            },
            datasetDates(){
                let start = new Date(this.tick_data[0].time);
                
                let end = new Date(this.tick_data[this.tick_data.length - 1].time);
                return start.toLocaleDateString() + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString() + " " + end.toLocaleTimeString();
            },
            formated_data(){
                
                return this.dailyChart.tick_data.map(td => {
                    return [parseFloat(td.time), parseFloat(td.open), parseFloat(td.high), parseFloat(td.low), parseFloat(td.close)]
                })
            }
        }, 
        methods: {

            // async getData(){

            //      this.loading = true;
            
            //     let results = await this.api.get(`/tickers/${this.ticker}/trends`, {
            //         timeframe: this.timeframe,
            //         multiplier: this.multiplier,
            //         start_date: this.start_date,
            //         end_date: this.end_date,
            //         period_length: this.period_length,
            //         trend_fuzziness: this.trend_fuzziness,
            //         trend_touches: this.trend_touches,
            //         min_trend_length: this.min_trend_length
            //     });  
                
            //     this.ticks = results.ticks;
            //     this.tick_data = JSON.parse(JSON.stringify(this.ticks)); 
            //     this.maximas = results.maximas
            //     this.minimas = results.minimas
            //     this.trends = results.trends
            //     this.trend = results.trend
                
            //     this.active.maximas = results.trends.maximas.slice(0,1);
            //     this.active.minimas = results.trends.minimas.slice(0,1);


            //     this.loading = false;
            //     this.createChart();


            // },



            createChart(){

                try{
                    this.chart.destroy();
                } catch(err){

                }

                this.chart_options.series = [];
                this.chart_options.series.push({
                    id: 'primary_stock', 
                    type: 'candlestick',
                    name: 'Stock Price',
                    data: this.formated_data,
                    dataGrouping: {
                        units: [
                            [
                                'minute', // unit name
                                [1, 5, 15] // allowed multiples
                            ]                                      
                        ]
                    }
                })


                 this.addMinimasMaximas();
                 if(this.lines.indexOf('horizontal_support') >= 0){
                    this.addHorizontalSupport();
                 }
                if(this.lines.indexOf('trendlines') >= 0){
                    this.addTrendLines();
                }
                if(this.lines.indexOf('smas') >= 0){
                    this.addMovingAverages();
                }
                
                this.chart = Highcharts.stockChart('trend-chart', this.chart_options); 
                if(this.chart_options.series[0].data){
                    this.chart.xAxis[0].setExtremes(this.chart_options.series[0].data[0].time, this.chart_options.series[0].data[0].time)
                    this.chart.yAxis[0].setExtremes(Math.min(...this.chart_options.series[0].data.map(dp => dp[3])) * .95  , Math.max(...this.chart_options.series[0].data.map(dp => dp[2])) * 1.05 )
                }
            }, 
            addHorizontalSupport(){
                if(this.dailyChart.horizontal_support.maximas){
                   
                    for(let i = 0; i < this.dailyChart.horizontal_support.maximas.length; i++){
                   
                        this.chart_options.series.push({
                            type: 'line',
                            color: '#2a9d8f', 
                            fillColor: '#323232',
                            title: 'Horizontal Support',
                            text: null,
                            data: [
                                [this.dailyChart.horizontal_support.maximas[i].time, this.dailyChart.horizontal_support.maximas[i].price],
                                [this.formated_data[this.formated_data.length - 1][0], this.dailyChart.horizontal_support.maximas[i].price ],
                            ]
                        });
                        
                    }
                }   
                
                for(let i = 0; i < this.dailyChart.horizontal_support.minimas.length; i++){
                   
                    this.chart_options.series.push({
                        type: 'line',
                        color: '#2a9d8f', 
                        fillColor: '#323232',
                        title: 'Horizontal Support',
                        text: null,
                        data: [
                            [this.dailyChart.horizontal_support.minimas[i].time, this.dailyChart.horizontal_support.minimas[i].price],
                            [this.formated_data[this.formated_data.length - 1][0], this.dailyChart.horizontal_support.minimas[i].price ],
                        ]
                    });
                
                
                }   
            }, 
            addTrendLines(){
                
                
                if(this.dailyChart.trends.maximas){
                   
                    for(let i = 0; i < this.dailyChart.trends.maximas.length; i++){
                   
                        this.chart_options.series.push({
                        type: 'line',
                        color: '#2a9d8f', 
                        fillColor: '#323232',
                        title: 'Trend Line',
                        text: `${this.dailyChart.trends.maximas[i].points.length}, ${this.dailyChart.trends.maximas[i].magnitude.toFixed(2)}, ${this.dailyChart.trends.maximas[i].strength}`,
                        data: [
                                [this.dailyChart.trends.maximas[i].start.time, this.dailyChart.trends.maximas[i].start.price],
                                [this.formated_data[this.formated_data.length - 1][0], (this.dailyChart.trends.maximas[i].m * this.formated_data.length) + this.dailyChart.trends.maximas[i].b ],
                            ]
                        });
                    
                    }
                }   
                
                if(this.dailyChart.trends.minimas){
                    
                    for(let i = 0; i < this.dailyChart.trends.minimas.length; i++){
                        this.chart_options.series.push({
                            type: 'line',
                            color: '#2a9d8f', 
                            fillColor: '#323232', 
                            title: 'Trend Line',
                            text: `${this.dailyChart.trends.minimas[i].points.length}, ${this.dailyChart.trends.minimas[i].magnitude.toFixed(2)}, ${this.dailyChart.trends.minimas[i].strength}`,
                            data: [
                                [this.dailyChart.trends.minimas[i].start.time, this.dailyChart.trends.minimas[i].start.price],
                                [this.formated_data[this.formated_data.length - 1][0], (this.dailyChart.trends.minimas[i].m * (this.formated_data.length - 1)) + this.dailyChart.trends.minimas[i].b ],,
                               // [this.trends.minimas[i].end.time, this.trends.minimas[i].end.price],
                            ]
                        });
                    
                    }
                }   
            }, 

            addMovingAverages(){
                
                
                if(this.dailyChart.sma50.length){
                    for(let i = 0; i < this.dailyChart.sma50.length; i++){
                        this.chart_options.series.push({
                            type: 'line',
                            color: '#f1c40f', 
              
                            title: '50 MA',
                            text: '50 MA',
                            data: this.dailyChart.sma50.map(sma => [sma.time, sma.value]) 
                        });
                    
                    }
                }   
                if(this.dailyChart.sma100.length){
                    for(let i = 0; i < this.dailyChart.sma100.length; i++){
                        this.chart_options.series.push({
                            type: 'line',
                            color: '#e67e22', 
                            
                            title: '100 MA',
                            text: '100 MA',
                            data: this.dailyChart.sma100.map(sma => [sma.time, sma.value]) 
                        });
                    
                    }
                }   
                
                if(this.dailyChart.sma200.length){
                    for(let i = 0; i < this.dailyChart.sma200.length; i++){
                        this.chart_options.series.push({
                            type: 'line',
                            
                            color: '#e74c3c', 
                            title: '200 MA',
                            text: '200 MA',
                            data: this.dailyChart.sma200.map(sma => [sma.time, sma.value]) 
                        });
                    
                    }
                }   
            
            }, 

            addStop(price){

               this.chart_options.yAxis.plotLines.push({
                  color: '#e76f51', // Color value
                  dashStyle: 'solid', // Style of the plot line. Default to solid
                  value: price, 
                  width: 2 // Width of the line    
               })

            }, 

             addMinimasMaximas(){
                //this.chart_options.series.splice(1);
                let flag_series = {
                    maximas: {
                        type: 'flags',
                        data: [],
                        color: '#FFFFFF', 
                        fillColor: '#2a9d8f',
                        shape: 'square',
                        width: 12
                    },
                    minimas: {
                        type: 'flags',
                        data: [],
                        color: '#FFFFFF',
                        fillColor: '#2a9d8f',
                        shape: 'flag',
                        width: 12
                    }, 
                    // stop: {
                    //     type: 'flags',
                    //     data: [],
                    //     color: '#FFFFFF',
                    //     fillColor: '#e76f51', 
                    //     shape: 'flag',
                    //     width: 16
                    // }
                } 
                
                 
                if(this.maximas.length) {
                    for(let i = 0; i < this.maximas.length; i++){
                        let t = this.maximas[i];
                        flag_series.maximas.data.push({
                            x: t.time,
                            y: parseFloat(t.price),
                            title: 'H',
                            text: `$${t.price}`
                        });
                    }
                    if(flag_series.maximas.data.length){
                        this.chart_options.series.push(flag_series.maximas);
                    }
                } 
              
                if(this.minimas.length) {
                    for(let i = 0; i < this.minimas.length; i++){
                        let t = this.minimas[i];
                        flag_series.minimas.data.push({
                            x: +t.time,
                            y: parseFloat(t.price * .98),
                            title: 'L',
                            text: `$${t.price}`
                        });
                    }
                    if(flag_series.minimas.data.length){
                        this.chart_options.series.push(flag_series.minimas);
                    }
                } 
                

                
             }
        },
        watch: {
            activeTicker(){  
                if(this.isLoaded){
                    this.createChart();
                }
            },
           isLoaded(){
                this.createChart();
            },
            lines: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler(){
                    if(this.isLoaded){   
                        this.createChart();
                    }
                }
            }, 
            selectedTimeframe(){
                  if(this.isLoaded){
                        this.createChart();
                    }
            }
            
    

        }
    }
</script>

<style scoped>
    #chart {
        background: #FFFFFF;
        color: #A0A0A0;
        width: 100%;
        height: 100%;
    }
    
</style>