<template>
    <div class="d-flex flex-column justify-start align-stretch">
        
        
    <v-card> 
        <v-card-title class="text-h6">Configure Scan <v-spacer></v-spacer>
        <v-btn v-show="scan.id" elevation="0" text small dense @click="duplicate" ><v-icon small>mdi-content-copy</v-icon></v-btn></v-card-title>
        <v-alert v-if="error" dense color="error" dark> {{error}} </v-alert>
        <v-card-text>
            <v-container class="pa-0">
                <v-row no-gutters>
                    <v-col class="px-2">
                        <v-text-field dense hide-details outlined label="Scan Name" v-model="scan.name"></v-text-field>
                    </v-col>
                    
                </v-row>
                
                <v-divider class="my-4"></v-divider>
                <v-row no-gutters>
                    
                    <v-col class="px-4">
                        Trade Direction<b r/>
                        <v-radio-group class="my-0 mr-0 pr-0" dense hide-details row v-model="scan.side">
                            <v-radio
                                label="Long"
                                value="long"
                            ></v-radio>
                            <v-radio
                                label="Short"
                                value="short"
                            ></v-radio>
                            </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-row no-gutters >
                    <v-col class="px-4"><v-checkbox class="pa-0 d-inline-flex" dense hide-details row v-model="scan.to_alert" label="Alert on update"></v-checkbox></v-col>
                </v-row>
                

                <v-divider class="my-3"></v-divider>
                
                <v-row no-gutters class="mt-10" >
                    <v-col class="px-4 text-subtitle">Indicator list</v-col>
                    <v-col class="px-4 text-subtitle text-right" >
                        <span v-show="estimate_loading"><v-progress-circular v-show="estimate_loading" size="24" indeterminate color="primary"></v-progress-circular></span>
                        <span v-show="estimate > 0">{{estimate}} Monthly results</span>
                    </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                
                
                <v-row dense v-for="(s, i) in scan.Settings " :key="i">
                    <v-col cols="1" class="px-3" ><v-checkbox v-model="s.active" dense hide-details></v-checkbox></v-col>
                    <v-col class="py-3">{{s.Timeframe.name}} {{s.Indicator.name}} </v-col>
                    <v-col cols="4">
                        <div v-if="s.Indicator.datatype == 'Number'">
                            <div class="d-flex flex-row column-filter">
                                <div style="flex: 0 0 40px;" class="mr-1 mt-1">
                                    <v-btn-toggle background-color="white" v-model="s.comparator" color="primary">
                                        <v-btn height="32" color="white" width="10" style="width: 20px" small value="greaterThan">
                                            <v-icon small>mdi-chevron-right</v-icon>
                                        </v-btn>
                                        <v-btn height="32" color="white" width="10" small value="lessThan">
                                            <v-icon small>mdi-chevron-left</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                                <div style="flex: 0 0 75px;">
                                        <v-text-field
                                        
                                            dense
                                            background-color="white"
                                            hide-details
                                            :value="s.value" 
                                            @change="value => s.value = value"
                                              
                                            outlined 
                                            width="50"
                                        ></v-text-field>
                                    </div>
                       
                                </div>
                        </div> 

                        <div v-if="s.Indicator.datatype == 'Boolean'" class="column-filter">
                            <v-btn-toggle v-model="s.value" background-color="white" >
                                <v-btn height="32" depressed :color="s.value === '1' ? 'success': 'white'" width="10"  small value="1">
                                    <v-icon small :color="s.value === '1' ? 'white': null">mdi-check</v-icon>
                                </v-btn>
                                <v-btn height="32" width="10" depressed :color="s.value === '0' ? 'error': 'white'" small value="0">
                                 <v-icon small :color="s.value === '0' ? 'white': null">mdi-close</v-icon>
                                </v-btn>
                            </v-btn-toggle> 
                        </div> 
                        <div v-if="s.Indicator.datatype == 'List'">
                            <v-select
                                hide-details
                                multiple
                                :value="s.value" 
                                @change="value => s.value = value"
                                
                                :items="s.Indicator.config.options.map(o => o.toLowerCase())"
                                outlined
                                dense
                                >
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="s.value.length == 1">{{ item }}</span>
                                    <span v-else-if="index === 0"> {{s.value.length}} items</span>
                                </template>
                                
                                </v-select>
                        </div> 



                        <!-- {{s}} -->
                    </v-col>
                    <v-col cols="1" class="pl-2 pt-1 pb-0">
                        <v-btn color="error" @click="removeItem(i)" small text class="cancel-btn px-0"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                    </v-col>
                </v-row>
    
            <v-row class=" px-3"  v-show="showAddScan">
               <v-col cols="8" class="pl-3 pr-2 configuration-row" >
                    <v-autocomplete
                        background-color="white"
                        v-model="selected_setting.Indicator"
                        label="Choose Indicator"
                        :items="indicators"
                        item-text="name"
                        return-object
                        outlined
                        dense
                        hide-details
                    ></v-autocomplete>
               </v-col>
                
               <v-col cols="3" class="pl-0 pr-3 configuration-row">
                 <v-select
                    background-color="white"
                    :items="timeframes"
                    v-model="selected_setting.Timeframe"
                    label="TF"
                    return-object
                    item-text="name"
                    outlined
                    dense
                    hide-details
                    ></v-select>
               </v-col>
                <v-col cols="1" class="pl-0 pr-3 pb-0 configuration-row">
                  <v-btn @click="addScanToConfigObj" class="filter-config px-0" elevation="0" color="primary" dense :disabled="!selected_setting.Timeframe || !selected_setting.Indicator" ><v-icon>mdi-chevron-right</v-icon></v-btn>
               </v-col>
               <!-- <v-col cols="12" class="pb-0" v-show="selected_setting.timeframe && selected_setting.Indicator">

                    <div v-if="selected_setting.Indicator.datatype == 'Number'">
                        Number
                    </div> 
                    <div v-if="selected_setting.Indicator.datatype == 'Boolean'"></div> 
                   
                    <div v-if="selected_setting.Indicator.datatype == 'List'">
                        <v-select
                            background-color="white"
                            :items="selected_setting.Indicator.config.options"
                            v-model="selected_setting.value"
                            label="Timeframe"
                            outlined
                            dense
                            hide-details
                        ></v-select>
                    </div>
               </v-col> -->

               <!-- <v-col class="text-right" cols="12" >
                   <v-btn elevation="0" color="primary" dense >Set Filter</v-btn>
               </v-col> -->
               
            </v-row>

                
                <v-row no-gutters class="mt-4 px-3" v-show="!showAddScan">
                    <v-col>
                        
                        <v-btn elevation="0" dense small color="primary" text class="mr-1 px-0" @click="addScan"><v-icon>mdi-plus</v-icon>Add Indicator</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            
            <v-btn v-if="scan.id" elevation="0" dense color="error" @click="deleteScan">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn elevation="0" dense color="neutral" @click="$emit('close')">Close</v-btn>
            <v-btn elevation="0" dense color="primary" @click="saveScan">Save</v-btn>
        </v-card-actions>

    </v-card>
    </div>
        
    </template>'

</template>
<script>
    export default {
        name: 'Scanner',
        data: () => ({
            estimate: 0,
            estimate_loading: false,
            error: null,
            scan: {
                id: '',
                name: "", 
                side: 'Long',
                to_alert: false,
                Settings: [],
            },
            showAddScan: false,
            selected_setting: {
                active: true,
                Timeframe: '', 
                Indicator: '',
                value: null, 
                comparator: null, 
            }
        }),
        props: ['selected', 'timeframes', 'indicators'], 
        created(){
            if(this.selected && this.selected.name){
                this.scan = JSON.parse(JSON.stringify(this.selected));
            }
        }, 
        methods: {
            removeItem(index){
                this.scan.Settings.splice(index,1)
            }, 
            addScan(){
                this.showAddScan = true;
            }, 
            async saveScan(){

                if(!this.validate()) return;
                try {
                    await this.$store.dispatch('scannerStore/saveScan',  this.scan);   
                    this.$emit('saved', this.scan);
                    this.cancelScan();
                    this.scan = {
                        id: '',
                        name: "", 
                        Settings: [],
                    }    
                } catch(err){
                    console.log("err", err)
                }


                
            }, 
            validate(surpress_errors){
                this.error = false;
                if(!this.scan.name){
                    this.error = !surpress_errors ?"Give this scan a name" : null; 
                    return false;
                }
                for(let i = 0; i < this.scan.Settings.length; i++){
                    if(this.scan.Settings[i].value === null){
                        this.error = !surpress_errors ? "Not all your scans have appropriate values": null; 
                        return false;
                    }
                    if(this.scan.Settings[i].Indicator.datatype === 'Number' && (!this.scan.Settings[i].comparator || !this.scan.Settings[i].value)){
                        this.error = !surpress_errors ? "Not all your scans have appropriate values" : null; 
                        return false;
                    }
                    // if(this.scan.Settings[i].Indicator.datatype === 'List' ){
                    //     this.error = "Not all your scans have appropriate values"; 
                    //     return false;
                    // }
                }
                return true;
            },
            cancelScan(){
                this.selected_setting = {
                    id: '',
                    checked: true,
                    Timeframe: {}, 
                    Indicator: {},
                    comparator: '', 
                    value: ''
                }
                this.showAddScan = false
            },
            async deleteScan(){

                try {
                    await this.$store.dispatch('scannerStore/deleteScan',  this.scan.id);   
                    this.$emit('delete', this.scan.id);
                    this.cancelScan();
                    this.scan = {
                        id: '',
                        name: "", 
                        Settings: [],
                    }    
                } catch(err){
                    console.log("err", err)
                }

            }, 
            addScanToConfigObj(){
                this.scan.Settings.push({
                    ...this.selected_setting
                    // key: this.selected_setting.Timeframe.label.toLowerCase() + '_' + this.selected_setting.Indicator.key,
                    // name: this.selected_setting.Timeframe.name + ' ' + this.selected_setting.Indicator.name,
                });
                this.cancelScan();
               
            },
            duplicate(){
                this.scan.id = null;
                this.scan.name = null;
            },
            async getEstimate(){
                this.estimate_loading = true;
                this.estimate = 0;
                let result =  await this.api.post(`/scanner/estimate`, this.scan); 
                this.estimate_loading = false;
                this.estimate = result.count;
            }
        },
        watch:{
            "scan.Settings": {
                deep: true,
                handler(settings, old){
                    if(!this.validate(true)) return;
                    this.getEstimate();
                }
            }
        }
    }
</script>
<style scoped>

    .configuration-row{
        background-color: #eef2f3;
    }
</style>
<style>
.cancel-btn.v-btn:not(.v-btn--round), 
.filter-config.v-btn:not(.v-btn--round) {
        padding: 0 0px;
        min-width: 25px;
}
.filter-panel.theme--light.v-sheet{
    background-color: #eef2f3;
    padding: 10px;
    margin-bottom: 15px;
}
</style>
