<template>
    <div class="d-flex flex-column justify-start align-stretch">
        
        
    <v-card> 
        <v-card-title class="text-h6">Scan Metrics for {{activeTicker}}</v-card-title>
          <v-divider></v-divider>
        <v-card-text>
            
            <v-container>
                <v-row  class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label"><strong>Indicator</strong></v-col>
                    <v-col class="metrics-value"><strong>M5</strong></v-col>
                    <v-col class="metrics-value"><strong>D1</strong></v-col>
                </v-row>

                <v-row class="table-row heading" dense >
                    <v-col  class="metrics-label">Strength</v-col>
                </v-row>

                
                 <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Relative Strength</v-col>
                  
                    <v-col class="metrics-value">{{metrics.minute.relative_strength}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.relative_strength}}</v-col> 
                </v-row>

    
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Cumulative Strength</v-col>
                    <v-col class="metrics-value">{{metrics.minute.overall_strength}}</v-col>
                    <v-col class="metrics-value">-</v-col>
                </v-row> 
                 


                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Stacked RS</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.stacked_rs"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.stacked_rs"></ListRenderer></v-col>
                </v-row>

                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Change in Strength</v-col>
                    <v-col class="metrics-value">{{metrics.minute.gaining_strength}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.gaining_strength}}</v-col>
                </v-row>


                
                <v-row class="table-row heading" dense >
                    <v-col  class="metrics-label">Volume</v-col>
                </v-row>


                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">RRVOL</v-col>
                    <v-col class="metrics-value">{{metrics.minute.rrvol}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.rrvol}}</v-col>
                </v-row>


                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Change In RRVOL</v-col>
                    <v-col class="metrics-value">{{metrics.minute.gaining_rvol}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.gaining_rvol}}</v-col>
                </v-row>


                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Unusual Volume</v-col>
                    <v-col class="metrics-value">{{metrics.minute.unusual_volume}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.unusual_volume}}</v-col>
                </v-row>



                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Recent RRVOL</v-col>
                    <v-col class="metrics-value">{{metrics.minute.recent_rrvol}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.recent_rrvol}}</v-col>
                </v-row>


                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Volume Spike</v-col>
                    <v-col class="metrics-value"><BooleanRenderer align="text-right" :value="metrics.minute.volume_spike"></BooleanRenderer></v-col>
                    <v-col class="metrics-value"><BooleanRenderer align="text-right" :value="metrics.day.volume_spike"></BooleanRenderer></v-col>
                </v-row>



                <v-row class="table-row heading" dense >
                    <v-col  class="metrics-label">Movement</v-col>
                </v-row>




                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Current Move</v-col>
                    <v-col class="metrics-value">{{metrics.minute.current_move}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.current_move}}</v-col>
                </v-row>
                
                
                 <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">ATR</v-col>
                    <v-col class="metrics-value">{{metrics.minute.atr}}</v-col>
                    <v-col class="metrics-value">{{metrics.day.atr}}</v-col>
                </v-row>
                
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Past Previous High Low</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.past_high_low"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.past_high_low"></ListRenderer></v-col>
                </v-row>
            
                
                <v-row class=" table-row" dense>
                    <v-col cols="6" class="metrics-label">ATH/ATL</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.ath"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.ath"></ListRenderer></v-col>
                </v-row>    
                
                
                
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Next Resistance</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.resistance"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.resistance"></ListRenderer></v-col>
                </v-row>
                

                
                
                
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Above/Below VWAP</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.direction"></ListRenderer></v-col>
                    <v-col class="metrics-value">-</v-col>

                </v-row>
                
            
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">3/8 Cross</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.cross_3_8"></ListRenderer></v-col>
                    <v-col class="metrics-value">-</v-col>
                </v-row>


                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Touch VWAP</v-col>
                    <v-col class="metrics-value"><BooleanRenderer align="text-right" :value="metrics.minute.touch_vwap"></BooleanRenderer></v-col>
                    <v-col class="metrics-value">-</v-col>
                </v-row>
                
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Touch 8EMA</v-col>
                    <v-col class="metrics-value"><BooleanRenderer align="text-right" :value="metrics.minute.touch_8ema"></BooleanRenderer></v-col>
                    <v-col class="metrics-value">-</v-col>
                </v-row>
                
        
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Touch 20EMA</v-col>
                    <v-col class="metrics-value"><BooleanRenderer align="text-right" :value="metrics.minute.touch_20ema"></BooleanRenderer></v-col>
                    <v-col class="metrics-value">-</v-col>
                </v-row>


                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Crossed MA</v-col>
                    <v-col class="metrics-value">-</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.crossed_ma"></ListRenderer></v-col>
                </v-row>
                
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Above/Below MAs</v-col>
                    <v-col class="metrics-value">-</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.above_below_mas"></ListRenderer></v-col>
                </v-row>


                <v-row class="table-row heading" dense >
                    <v-col  class="metrics-label">Trends</v-col>
                </v-row>

                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Crossed Trend Line</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.cross_trend"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.cross_trend"></ListRenderer></v-col>
                </v-row>
                
                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Crossed Horizontal Support</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.cross_horizontal_support"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.cross_horizontal_support"></ListRenderer></v-col>
                </v-row>

                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">Heiken Ashe Trend</v-col>
                    
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.heiken_ashe_trend"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.heiken_ashe_trend"></ListRenderer></v-col>
                </v-row>

                <v-row class=" table-row" dense>
                    <v-col  cols="6" class="metrics-label">HA Reversal</v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.minute.ha_reversal"></ListRenderer></v-col>
                    <v-col class="metrics-value"><ListRenderer align="text-right" :value="metrics.day.ha_reversal"></ListRenderer></v-col>
                </v-row> 



            </v-container>

        </v-card-text>
        <!-- <v-divider></v-divider> 
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" dense color="primary" @click="close">Close</v-btn>
        </v-card-actions> -->

    </v-card>
    </div>
        
    </template>

</template>
<script>
    
    import { mapGetters, mapMutations } from 'vuex';
import ListRenderer from '../scanner/renderers/ListRenderer.vue';
import BooleanRenderer from '../scanner/renderers/BooleanRenderer.vue';

    export default {
        name: 'ScanMetrics',
        data: () => ({
           
        }),
        props: ['metrics'], 
        created(){
        

        }, 
        components:{
            ListRenderer,
            BooleanRenderer
        }, 
        computed: {
            ...mapGetters({
                
                scans: 'getScansStore/getScans', 
                activeTicker: 'dashboardStore/getActiveTicker', 
            }),
            scanMetrics() {
                //return this.scans.find(s => s.)
            }
        },
        methods: {
            close(){
                
                this.$emit('close')
            }
        }
    }
</script>
<style scoped>
.metrics-value{
    text-align: right;
    font-weight: bold;
}
.heading{
    background-color: #eef2f3;
    
}
.table-row{
    border-bottom: 1px solid #e2e2e2;
}
</style>
