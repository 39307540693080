<template>
    <div :class="align ? align : 'text-center'">
        <v-icon medium color="success" v-if="renderValue == 1">mdi-menu-up</v-icon>
        <v-icon medium color="error" v-else-if="renderValue == -1">mdi-menu-down</v-icon>
        <v-icon small color="neutral" class="text--darken-1" v-else-if="renderValue == 0">mdi-close</v-icon>  
        <span v-else>{{ renderValue }}</span> 
    </div>
</template>

<script>
export default {
    data() {
        return {
            renderValue: null,
        }
    },
    props: ['value', 'align', 'dense'], 
    beforeMount() {
        
        if(typeof this.value !== 'undefined'){
            this.renderValue = this.value
        } else {
            this.renderValue = this.params.value
        }
    },
    methods: {
        refresh(params) {
            this.renderValue = this.params.value;
        },
    },
    watch:{
        value(newval, oldval){
            
            this.renderValue = newVal;
        }
    }
}
</script>