<template>
    <div :class="align ? align : 'text-center'">
       <v-icon small color="success" v-show="renderValue == 1">mdi-check-bold</v-icon>
       <v-icon small color="neutral" class="text--darken-1" v-show="renderValue == '0'">mdi-close</v-icon> 
    </div>
</template>

<script>
export default {
    data() {
        return {
            renderValue: null,
        }
    },
    props: ['value','align'], 
    beforeMount() {
      
        if(typeof this.value != 'undefined'){
            this.renderValue = this.value
        } else {
            this.renderValue = this.params.value
        }
    },
    methods: {
        refresh(params) {
            this.renderValue = +this.params.value;
        },
    }
}
</script>