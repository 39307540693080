<template>
    <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" depressed color="neutral" min-width="50" class="px-0" ><v-icon color="#a0a0a0">mdi-menu</v-icon></v-btn>
        </template>
            <v-card elevation="0">
                <v-row no-gutters>
                    <v-col>
                        <v-subheader>Intraday</v-subheader>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-checkbox @change="change" class="pa-0 ma-0 text--darken-4" small dense hide-details v-model="lines" value="emas"></v-checkbox>
                                </v-list-item-icon>
                                <v-list-item-title dense>EMAs</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="intraday_trendlines"></v-checkbox>
                                </v-list-item-icon>
                                <v-list-item-title>Trendlines</v-list-item-title>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="intraday_horizontal_support"></v-checkbox>
                                </v-list-item-icon>
                                <v-list-item-title>Horizontal Support</v-list-item-title>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="hloc"></v-checkbox>
                                </v-list-item-icon>
                                <v-list-item-title>HOLC</v-list-item-title>
                            </v-list-item>
                        </v-list>   
                    </v-col>

                    <v-col>
                        <v-subheader>Daily</v-subheader>
                        
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="trendlines"></v-checkbox>
                                </v-list-item-icon>
                                <v-list-item-title dense>Trendlines</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="horizontal_support_daily"></v-checkbox>
                                </v-list-item-icon>
                                <v-list-item-title>Horizontal Support</v-list-item-title>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="smas"></v-checkbox>
                                </v-list-item-icon>
                                <v-list-item-title>SMAs</v-list-item-title>
                            </v-list-item>
                        </v-list>   
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col>
                        <v-list dense >
                            <v-list-item >
                            <v-list-item-icon>
                                <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="compression_boxes"></v-checkbox>
                            </v-list-item-icon>
                            <v-list-item-title>Show Compression Boxes</v-list-item-title>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-icon>
                                <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="consolidation"></v-checkbox>
                            </v-list-item-icon>
                            <v-list-item-title>Show Consolidation</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-checkbox @change="change" class="pa-0 ma-0" small dense hide-details v-model="lines" value="ha_candles"></v-checkbox>
                            </v-list-item-icon>
                            <v-list-item-title>Show HA Candles</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card>
    </v-menu>
</template>
<script>

export default {
        name: 'ChartLines',
         data: () => ({
             lines: []
         }),
         props: ['value'], 
         created(){
             this.lines = JSON.parse(JSON.stringify(this.value)); 
         },
         methods:{
             change(){
                 this.$emit('input', this.lines)
             }
         }
}
</script>
