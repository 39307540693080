<template>
    <div class="d-flex flex-column justify-start align-stretch">
        
        
    <v-card> 
        <v-card-title class="text-h6">Configure Alerts for {{activeTicker}} <v-spacer></v-spacer>
        <!-- <v-btn v-show="filter.id" elevation="0" color="neutral" text small dense @click="duplicate" ><v-icon small>mdi-content-copy</v-icon></v-btn> -->
        </v-card-title>
        <v-alert v-if="error" dense color="error" dark> {{error}} </v-alert>
        <v-card-text>
            <v-container class="pa-0">
                <v-row dense v-for="(s, i) in alert_list " :key="i">
                    <v-col cols="1" ><v-checkbox v-model="s.checked" dense hide-details></v-checkbox></v-col>

                    <v-col class="py-3">{{s.timeframe.name}} {{s.indicator.name}}</v-col>
                    <v-col cols="4">
                        
                        <div v-if="s.indicator.datatype == 'Number'">
                            <div class="d-flex flex-row column-filter">
                                <div style="flex: 0 0 40px;" class="mr-1 mt-1">
                                    <v-btn-toggle background-color="white" v-model="s.comparator" color="primary">
                                        <v-btn @click="setActive(s)" height="32" color="white" width="10" style="width: 20px" small value="greaterThan">
                                            <v-icon small>mdi-chevron-right</v-icon>
                                        </v-btn>
                                        <v-btn @click="setActive(s)" height="32" color="white" width="10" small value="lessThan">
                                            <v-icon small>mdi-chevron-left</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                                <div style="flex: 0 0 75px;">
                                    
                                        <v-text-field
                                            dense
                                            background-color="white"
                                            hide-details
                                            v-model="s.value"  
                                            outlined 
                                            width="50"
                                            @blur="setActive(s)"
                                        ></v-text-field>
                                    </div>
                       
                                </div>
                        </div> 
                        <div v-if="s.indicator.datatype == 'Boolean'" class="column-filter">
                            <v-btn-toggle v-model="s.value" background-color="white" >
                                
                                <v-btn @click="setActive(s)" height="32" depressed :color="s.value === '1' ? 'success': 'white'" width="10"  small value="1">
                                    <v-icon  small :color="s.value === '1' ? 'white': null">mdi-check</v-icon>
                                </v-btn>
                                <v-btn @click="setActive(s)" height="32" width="10" depressed :color="s.value === '0' ? 'error': 'white'" small value="0">
                                 <v-icon small :color="s.value === '0' ? 'white': null">mdi-close</v-icon>
                                </v-btn>
                            </v-btn-toggle> 
                        </div> 
                        <div v-if="s.indicator.datatype == 'List'">
                            <v-select
                                @change="setActive(s)"
                                hide-details
                                v-model="s.value"
                                :items="s.indicator.config.options"
                                outlined
                                dense
                                ></v-select>
                        </div> 



                      
                     </v-col>
                    <v-col cols="1" class="pl-2 pt-1 pb-0">
                        <v-btn v-show="!s.hideDelete" color="error" @click="removeItem(i)" small text class="cancel-btn px-0"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                    </v-col>
                </v-row>
    
            <v-row class="configuration-row" background="netural" v-show="showAddAlert">
               <v-col cols="8" class="pl-3 pr-2" >
                    <v-autocomplete
                        background-color="white"
                        v-model="alert.indicator"
                        label="Indicator"
                        :items="indicators"
                        item-text="name"
                        return-object
                        outlined
                        dense
                        hide-details
                    ></v-autocomplete>
               </v-col>
                
               <v-col cols="3" class="pl-0 pr-3">
                 <v-select
                    background-color="white"
                    :items="timeframes"
                    v-model="alert.timeframe"
                    label="TF"
                    return-object
                    item-text="name"
                    outlined
                    dense
                    hide-details
                    ></v-select>
               </v-col>
                <v-col cols="1" class="pl-0 pr-3 pb-0">
                  <v-btn @click="addAlertToConfigObj" class="filter-config px-0" elevation="0" color="primary" dense :disabled="!alert.timeframe || !alert.indicator" ><v-icon>mdi-chevron-right</v-icon></v-btn>
               </v-col>

 


               <!-- <v-col cols="12" class="pb-0" v-show="alert.timeframe && alert.indicator">

                    <div v-if="alert.indicator.datatype == 'Number'">
                        Number
                    </div> 
                    <div v-if="alert.indicator.datatype == 'Boolean'"></div> 
                   
                    <div v-if="alert.indicator.datatype == 'List'">
                        <v-select
                            background-color="white"
                            :items="alert.indicator.config.options"
                            v-model="alert.value"
                            label="Timeframe"
                            outlined
                            dense
                            hide-details
                        ></v-select>
                    </div>
               </v-col> -->

               <!-- <v-col class="text-right" cols="12" >
                   <v-btn elevation="0" color="primary" dense >Set Alert</v-btn>
               </v-col>  -->

            </v-row>

                
                <v-row no-gutters class="mt-4" v-show="!showAddAlert">
                    <v-col>
                        
                        <v-btn elevation="0" dense small color="primary" text class="mr-1 px-0" @click="addAlert"><v-icon>mdi-plus</v-icon>Add Alert</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            
            <v-btn v-if="alert.id" elevation="0" dense color="error" @click="deleteAlert">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn elevation="0" dense color="neutral" @click="$emit('close')">Close</v-btn>
            <v-btn elevation="0" dense color="primary" @click="saveAlert">Save</v-btn>
        </v-card-actions>

    </v-card>
    </div>
        
    </template>

</template>
<script>
    import { v4 as uuidv4 } from 'uuid';
    import { mapGetters, mapMutations } from 'vuex';


    export default {
        name: 'ConfigureAlerts',
        data: () => ({
            error: null,
            alert_list:[],
            showAddAlert: false,
            alert: {
                checked: true,
                hideDelete: false,
                scan_filter_id: null,
                comparator: null, 
                timeframe: {}, 
                indicator: {},
                value: null, 
            }
        }),
        props: ['alerts'], 
        created(){
            

            this.addIndicator('M5', 'last_price', {}, true);
            
            this.addIndicator('M5', 'touch_8ema', {}, true);
            this.addIndicator('M5', 'touch_20ema', {}, true);
            this.addIndicator('M5', 'touch_vwap', {}, true);
            
            this.addIndicator('D1', 'crossed_ma', {}, true)
            this.addIndicator('D1', 'cross_horizontal_support', {}, true)
            this.addIndicator('D1', 'cross_trend', {}, true);
        
            for(let i = 0; i < this.alerts.length; i++ ){
                let time = this.timeframes.find(t => t.label.toLowerCase() === this.alerts[i].timeframe.toLowerCase());
         
                this.addIndicator(time.name, this.alerts[i].ScanFilter.key, this.alerts[i], false);
            }

        }, 
        computed: {
            ...mapGetters({
                user: 'authenticationStore/getUser', 
                activeTicker: 'dashboardStore/getActiveTicker', 
                indicators: 'scannerStore/getIndicators',
                timeframes: 'scannerStore/getTimeframes'
            })
        },
        methods: {
            setActive(s){
                switch(s.indicator.datatype.toLowerCase()){
                    case "boolean": 
                        if(typeof s.value !== 'undefined'){
                            s.checked = true; 
                            return;
                        }
                        break;
                    case "list": 
                        console.log("s.value", s.value)
                        if(s.value){
                            s.checked = true; 
                            return;
                        }
                        break;
                    case "number": 
                        if(s.value && s.comparator){
                            s.checked = true; 
                            return;
                        }
                        break;
                }
                
            }, 
            addIndicator(timeframe, indicator, alert = {}, hideDelete){

                let ind = this.indicators.find(i => i.key === indicator);
                let time = this.timeframes.find(t => t.name === timeframe);
                
                let setting = {
                    id: alert.id,
                    checked: alert.id ? true: false,
                    hideDelete: hideDelete, 
                    indicator: ind,
                    timeframe: time,
                    comparator: alert?.comparator,
                    value: alert?.value || null,
                    key: time.label.toLowerCase() + '_' + ind.key,
                    name: time.name + ' ' + ind.name,
                };

                 let existing_setting_index = this.alert_list.findIndex(s => s.key == time.label.toLowerCase() + '_' + ind.key);

                 
                if(existing_setting_index >= 0){
                    this.$set(this.alert_list, existing_setting_index, setting)
                    //this.alert_list.splice(existing_setting_index, setting);
                } else {
                    this.alert_list.push(setting)
                }



            }, 
            removeItem(index){
                this.alert_list.splice(index,1)
            }, 
            addAlert(){
                this.showAddAlert = true;
            }, 
            saveAlert(){
                
                if(!this.validate()) return;
                let alerts = this.alert_list.filter(al => al.checked); 

                this.$emit('close');
                this.$store.dispatch('notificationsStore/saveAlerts', {
                    alerts, 
                    ticker: this.activeTicker
                });
                
            }, 
            validate(){
                this.error = false;
                

                for(let i = 0; i < this.alert_list.length; i++){
                    if(!this.alert_list[i].checked) continue;
                    
                    if(this.alert_list[i].value === null){
                        this.error = "Not all your filters have appropriate values"; 
                        return false;
                    }
                    
                    if(this.alert_list[i].indicator.datatype === 'Number' && !this.alert_list[i].comparator){
                        this.error = "Not all your filters have appropriate values"; 
                        return false;
                    }
                 
                    // if(this.alert_list[i].indicator.datatype === 'List' ){
                    //     this.error = "Not all your filters have appropriate values"; 
                    //     return false;
                    // }
                }
                return true;
            },
            cancelAlert(){
                this.alert = {
                    id: null,
                    checked: true,
                    hideDelete: false, 
                    timeframe: {}, 
                    indicator: {},
                    comparator: null, 
                    value: null
                }
                this.showAddAlert = false
            },
            deleteAlert(){

                this.$emit('delete', this.filter.id);
                this.cancelAlert();
                this.alert_list = []
            }, 
            addAlertToConfigObj(){
                this.alert_list.push({
                    ...this.alert, 
                    key: this.alert.timeframe.label.toLowerCase() + '_' + this.alert.indicator.key,
                    name: this.alert.timeframe.name + ' ' + this.alert.indicator.name,
                });
                this.cancelAlert();
               
            },
            duplicate(){
                this.filter.id = null;
                this.filter.name = null;
            }
        }
    }
</script>
<style scoped>

    .configuration-row{
        background-color: #eef2f3;
    }
</style>
<style>
.cancel-btn.v-btn:not(.v-btn--round), 
.filter-config.v-btn:not(.v-btn--round) {
        padding: 0 0px;
        min-width: 25px;
}
.filter-panel.theme--light.v-sheet{
    background-color: #eef2f3;
    padding: 10px;
    margin-bottom: 15px;
}
</style>
